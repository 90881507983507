import React from 'react'
import { Button, Container, Form, Modal, Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { CSVLink } from "react-csv";
import axios from 'axios';
import { BASE_PATH } from "../../utils/constants/myApiUrls";
import { MyStorageManager } from "../../utils";
import { BiMessageCheck } from "react-icons/all";

/**
 * Component to show a modal with different options for delete covid row
 *
 * @component
 *
 * @param {function} handleShow: tell the parent component if the modal needs to be shown
 * @param {function} handleSendingPerformed: tell the parent component the result of sending the reminder
 * @param showModal: if true, the modal is shown
 *
 * @example
 * const handleShow = show => setShowModal(show);
 * const handleSendingPerformed = satisfactory => setAlert(satisfactory);
 * const showModal = true;
 * const handleCreate = () => setShowModal(false);
 * <SendReminderModal patientId={patientId} handleShow={handleShow}
 * handleSendingPerformed={handleSendingPerformed} showModal={true} custom={true}/>
 */

const DownloadPDFModal = ({ handleShow, showModal = false, title, description, valuesCsvData, labelsCsvData, handleDownloadValue }) => {
  const handleClose = () => {
    handleShow(false)
  }
  const myStorageManager = new MyStorageManager();

  const handleDownloadPdf = async () => {
    try {
      // TODO: Change it to use ReportsServices
      const response = axios(`${BASE_PATH}/export-pdf/${showModal}`, {
        method: "GET",
        responseType: "blob", //Force to receive data in a Blob Format
        headers: {
          "Authorization": `Bearer ${myStorageManager.getToken()}`
        }
      })
        .then(response => {//Create a Blob from the PDF Stream
          const file = new Blob(
            [response.data],
            { type: "application/pdf" });//Build a URL from the file
          const fileURL = URL.createObjectURL(file);//Open the URL on new Window
          window.open(fileURL);
        })
        .catch(error => {
          { console.log(error.response) }
          <Alert

            className="rounded-pill"
            variant="danger"
            dismissible
          >
            <BiMessageCheck size="2rem" />
            {error.response.data.size}
          </Alert>

        });
      handleClose()
      // eslint-disable-next-line no-empty
    } catch (e) {

    }
  }

  return (
    <Modal
      size='lg'
      show={showModal}
      onHide={handleClose}
      backdrop='static'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Form>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          {/* <Button variant="primary" onClick={() => {
                        handleClose()
                        handleDownloadValue()
                        }}>
                        Export Labels
                    </Button> */}
          {/* <CSVLink data={labelsCsvData} filename='labels_table.csv'><Button onClick={() => {handleClose()}}>Export Labels</Button></CSVLink> */}
          <Button onClick={() => { handleDownloadPdf() }}>Export</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

DownloadPDFModal.propTypes = {
  handleShow: PropTypes.func.isRequired,
  handleDownloadValue: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default DownloadPDFModal;