import {createContext, useContext} from 'react';

export const AuthContext = createContext({
        isLoggedIn: false,
        token: null,
        roles: null,
        login: () => {
        },
        logout: () => {
        }
    }
);

export function useAuth() {
    return useContext(AuthContext);
}