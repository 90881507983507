import React from 'react';
import {Image} from "react-bootstrap";
import logo from "../../../assets/logo.png";

const Logo = ({size = '50px'}) => {
    return (
        <Image src={logo} style={{height: size, width: size}} fluid/>
    );
}

export default Logo;