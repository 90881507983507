import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import {
    defaultReminders,
    REMINDER_MAX_LENGTH,
    sendDefaultReminderTo,
    sendCustomReminderTo
} from "../../utils/constants/reminders";
import PropTypes from 'prop-types';
import './PatientsTable.css';
import NotificationsService from "../../utils/services/NotificationsService";
import { CSVLink } from "react-csv";

/**
 * Component to show a modal with different options for sending reminders or a custom one
 *
 * @component
 *
 * @param {object} patient
 * @param {function} handleShow: tell the parent component if the modal needs to be shown
 * @param {function} handleSendingPerformed: tell the parent component the result of sending the reminder
 * @param showModal: if true, the modal is shown
 * @param {boolean} custom: if true, the user can type a custom message for the reminder.
 *                          Otherwise, only default messages can be chosen
 *
 * @example
 * const patient = <your-patient>;
 * const handleShow = show => setShowModal(show);
 * const handleSendingPerformed = (isSatisfactory, message) => setAlert(message);
 * const showModal = true;
 * const handleCreate = () => setShowModal(false);
 * <SendReminderModal patient={patient} handleShow={handleShow}
 * handleSendingPerformed={handleSendingPerformed} showModal={true} custom={true}/>
 */
const SendReminderModal = ({ patient, logs, handleShow, handleSendingPerformed, showModal = false, custom = false }) => {

    const reminderTitle = custom ? sendCustomReminderTo : sendDefaultReminderTo;
    const [message, setMessage] = useState(custom ? '' : '0');
    const [csvData, setCsvData] = useState([["User", "Patient", "Date", "Message"]]);
    const [isCsvDone, setIsCsvDone] = useState(false);

    const handleSend = e => {
        e.preventDefault();
        const tokenFirebase = patient.token_firebase;

        if (!tokenFirebase) {
            handleSendingPerformed(false, `The patient ${patient.id} does not have a firebase token associated`);
        } else {
            // Using the notifications service
            NotificationsService.sendFcm(tokenFirebase, custom ? message : defaultReminders[message])
                .then(_ => {
                    postLog()
                    handleSendingPerformed(true, `The reminder was successfully sent to the patient ${patient.id}!`);
                }).catch(_ => {
                    handleSendingPerformed(false, `The reminder could not be sent to the patient ${patient.id}. Please, try again later`);
                });
        }
    }
    const createCsvData = () => {
        var newCsvData = csvData;
        Object.values(logs).map(log => {
            if (log.log !== "Hello. You have an IPOS form that is overdue. Please click here to open the App to complete the form as soon as you can. Thank you.") {
                newCsvData = [...newCsvData, [
                    log.user,
                    log.patient_id,
                    log.created_at,
                    log.log
                ]]
            }
        })
        setCsvData(newCsvData);
        setIsCsvDone(true);
    }

    if (!isCsvDone && logs) {
        createCsvData()
    }
    const postLog = async () => {
        try {
            const body = {
                "type": custom ? "notification" : "reminder",
                "value": null,
                "patient_id": patient.id,
                "report_id": null,
                "message": custom ? message : defaultReminders[message],
                "identifier": null
            }
            const responseNotification = await NotificationsService.postNotification(body)
            const response = await NotificationsService.postNotificationLog(custom ? "notification" : "reminder", custom ? message : defaultReminders[message], patient.id);
        } catch (err) {
            console.log(err)
        }
    };

    const handleClose = () => {
        handleShow(null)
    }

    return (
        <Modal
            size="lg"
            show={showModal}
            onHide={handleClose}
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{reminderTitle(patient.id)}</Modal.Title>
            </Modal.Header>

            <Form onSubmit={handleSend}>
                <Modal.Body>
                    <Container >
                        {custom ?
                            <Row>
                                <Col>
                                    <Form.Group controlId="formCustomMessage">
                                        <Form.Control
                                            as="textarea" rows={6} value={message}
                                            maxLength={REMINDER_MAX_LENGTH}
                                            onChange={e => setMessage(e.target.value)}
                                            placeholder="Type your custom message here..."
                                        />
                                    </Form.Group>
                                    {/* <Container style={{ display: 'flex', justifyContent: 'center' }}>
                                        <h5>
                                            Hello. You have an IPOS form that is overdue. Please click here to open the App to complete the form as soon as you can. Thank you.
                                        </h5>
                                    </Container> */}
                                    <div className='separator mt-5' />
                                    <Container style={{ display: 'flex', justifyContent: 'left' }}>
                                        <h6>
                                            MESSAGE LOGS
                                        </h6>
                                    </Container>
                                    {logs != null ? <Col>
                                        {logs.map((log) => {
                                            if (log.log !== "Hello. You have an IPOS form that is overdue. Please click here to open the App to complete the form as soon as you can. Thank you.") {
                                                return <li>
                                                    {log.user} sent a Custom Message: <b>{log.log}</b> on {log.created_at}
                                                </li>
                                            }
                                        })}
                                    </Col> : <></>}
                                </Col>
                            </Row> :
                            <Col>
                                <Container style={{ display: 'flex', justifyContent: 'center' }}>
                                    <h5>
                                        Hello. You have an IPOS form that is overdue. Please click here to open the App to complete the form as soon as you can. Thank you.
                                    </h5>
                                </Container>
                                <div className='separator' />
                                <Container style={{ display: 'flex', justifyContent: 'left' }}>
                                    <h6>
                                        REMINDERS LOGS
                                    </h6>
                                </Container>
                                {logs != null ? <Container style={{ flex: 1, height: '100px', border: '1px solid rgba(0, 0, 0, 0.4)', overflowY: 'scroll' }}>
                                    <ul>
                                        {logs.reverse().map((log) => {
                                            if (log.log !== "Hello. You have an IPOS form that is overdue. Please click here to open the App to complete the form as soon as you can. Thank you.") {
                                                return null
                                            } else {
                                                return <li>
                                                    {log.user} sent a Reminder on {log.created_at}
                                                </li>
                                            }

                                        })}
                                    </ul>
                                </Container> : <Container style={{ flex: 1, height: '100px', border: '1px solid rgba(0, 0, 0, 0.4)', overflowY: 'scroll' }}>
                                    <h6>
                                        <li>
                                            No reminders yet
                                        </li>
                                    </h6> </Container>}
                            </Col>
                        }
                    </Container>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <CSVLink data={csvData} filename='logs_reminder.csv'><Button onClick={() => { handleClose() }}>Export</Button></CSVLink>
                    <Button variant="primary" onClick={handleSend}>
                        Send
                    </Button>
                </Modal.Footer>

            </Form>
        </Modal >
    )
}

SendReminderModal.propTypes = {
    patient: PropTypes.object.isRequired,
    handleShow: PropTypes.func.isRequired,
    handleSendingPerformed: PropTypes.func.isRequired,
    custom: PropTypes.bool
}

export default SendReminderModal;