import React from 'react'
import { Button, Container, Form, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { CSVLink } from "react-csv";

/**
 * Component to show a modal with different options for delete covid row
 *
 * @component
 *
 * @param {function} handleShow: tell the parent component if the modal needs to be shown
 * @param {function} handleSendingPerformed: tell the parent component the result of sending the reminder
 * @param showModal: if true, the modal is shown
 *
 * @example
 * const handleShow = show => setShowModal(show);
 * const handleSendingPerformed = satisfactory => setAlert(satisfactory);
 * const showModal = true;
 * const handleCreate = () => setShowModal(false);
 * <SendReminderModal patientId={patientId} handleShow={handleShow}
 * handleSendingPerformed={handleSendingPerformed} showModal={true} custom={true}/>
 */

const DownloadCSVModal = ({ handleShow, showModal = false, title, description, valuesCsvData, labelsCsvData, handleDownloadValue }) => {

    const handleClose = () => {
        handleShow(false)
    }

    return (
        <Modal
            size='lg'
            show={showModal}
            onHide={handleClose}
            backdrop='static'
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Form>
                <Modal.Body>
                    <Container>
                        {description}
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    {/* <Button variant="primary" onClick={() => {
                        handleClose()
                        handleDownloadValue()
                        }}>
                        Export Labels
                    </Button> */}
                    {/* <CSVLink data={labelsCsvData} filename='labels_table.csv'><Button onClick={() => {handleClose()}}>Export Labels</Button></CSVLink> */}
                    <CSVLink data={valuesCsvData} filename='values_table.csv'><Button onClick={() => { handleClose() }}>Export</Button></CSVLink>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

DownloadCSVModal.propTypes = {
    handleShow: PropTypes.func.isRequired,
    handleDownloadValue: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
}

export default DownloadCSVModal;