import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

const NotFound = () => (
    <Container className="d-flex align-items-center justify-content-center min-vh-100">
        <Row>
            <Col>
                <h1>404 - Not Found!</h1>
            </Col>
        </Row>
    </Container>
);

export default NotFound;