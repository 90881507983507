export const genderOptions = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
];

export const liveOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
];

export const maritalOptions = [
    { value: 'Married', label: 'Married' },
    { value: 'Single', label: 'Single' },
    { value: 'Divorced', label: 'Divorced' },
    { value: 'Widowed', label: 'Widowed' },
    { value: 'Separated', label: 'Separated' },
    { value: 'Partner', label: 'Partner' },
    { value: 'Civil partnership', label: 'Civil partnership' },
    { value: 'Prefer not to say', label: 'Prefer not to say' },
];


export const ethnicityOptions = [
    { value: 'White British', label: 'White British' },
    { value: 'White Irish', label: 'White Irish' },
    { value: 'Other White', label: 'Other White' },
    { value: 'White and Black Caribbean', label: 'White and Black Caribbean' },
    { value: 'White and Black African', label: 'White and Black African' },
    { value: 'Other mixed background', label: 'Other mixed background' },
    { value: 'Indian', label: 'Indian' },
    { value: 'Pakistani', label: 'Pakistani' },
    { value: 'Bangladeshi', label: 'Bangladeshi' },
    { value: 'Other Asian', label: 'Other Asian' },
    { value: 'Caribbean', label: 'Caribbean' },
    { value: 'African', label: 'African' },
    { value: 'Other Black', label: 'Other Black' },
    { value: 'Chinese', label: 'Chinese' },
    { value: 'Other, please specify', label: 'Other, please specify' },
];

export const educationOptions = [
    { value: 'Primary School', label: 'Primary School' },
    { value: 'Secondary/High School', label: 'Secondary/High School' },
    { value: 'Diploma level or equivalent', label: 'Diploma level or equivalent' },
    { value: 'Bachelor level or equivalent', label: 'Bachelor level or equivalent' },
    { value: 'Master level or equivalent', label: 'Master level or equivalent' },
    { value: 'Other, please specify', label: 'Other, please specify' },
];

export const relationshipOptions = [
    { value: 'Relative, please specify', label: 'Relative, please specify' },
    { value: 'Friend', label: 'Friend' },
    { value: 'Other, please specify', label: 'Other, please specify' },
];

export const familyCareOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
];
export const careFamilyOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
];

export const placeOptions = [
    { value: 'Own home', label: 'Own home' },
    { value: 'Relative\'s home', label: 'Relative\'s home' },
    { value: 'Nursing home', label: 'Nursing home' },
    { value: 'Other, please specify', label: 'Other, please specify' },
];


export const religionOptions = [
    { value: 'Roman Catholic', label: 'Roman Catholic' },
    { value: 'Islam', label: 'Islam' },
    { value: 'Pentecostal', label: 'Pentecostal' },
    { value: 'Presbyterian', label: 'Presbyterian' },
    { value: 'Church of Christ', label: 'Church of Christ' },
    { value: 'No religion', label: 'No religion' },
    //{value: 'Other (please specify)', label: 'Other (please specify)'}
];

export const coMorbiditiesOptions = [
    { value: 'Hypertension', label: 'Hypertension' },
    { value: 'Diabetes', label: 'Diabetes' },
    { value: 'HIV', label: 'HIV' },
    { value: 'Hepatitis B', label: 'Hepatitis B' },
    { value: 'Heart failure', label: 'Heart failure' },
    { value: 'Chronic Obstructive Pulmonary Disease (COPD)', label: 'Chronic Obstructive Pulmonary Disease (COPD)' },
    { value: 'Other', label: 'Other' }
];

export const languageOptions = [
    { value: 'English', label: 'English' },
    { value: 'Arabic', label: 'Arabic' },
    { value: 'French', label: 'French' },
    { value: 'Swahili', label: 'Swahili' }
];