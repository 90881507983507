import React, { useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useAuth } from "../../context/auth";
import { Link, Redirect } from "react-router-dom";
import CustomNavbar from "../Dashboard/Navbar/CustomNavbar";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import * as demographics from "../../utils/constants/demographics";
import {
    selectArrayToValues,
    selectObjectToValue,
    valuesToSelectArray,
    valueToSelectObject
} from "../../utils/helperFunctions";
import { FcInfo } from "react-icons/all";
import PatientsService from "../../utils/services/PatientsService";

/**
 * Component to edit the patient demographics
 *
 * @param {object} match: information about how a <Route path> matched the URL
 * @param {object} history: needed to go back in the navigation history if the user clicks 'Cancel' in the form
 * @param {object} location: needed to retrieve the patient data
 */
const EditPatientDemographics = ({ match, history, location }) => {
    const patientId = match.params.patientId;
    const patientData = location.state ? location.state.patientData : null;
    const { isLoggedIn } = useAuth();
    const [redirect, setRedirect] = useState(false);

    const [age, setAge] = useState((patientData && patientData.age && String(patientData.age)) || '');
    const [gender, setGender] = useState((patientData && patientData.gender) ? valueToSelectObject(patientData.gender) : '');
    const [live, setLive] = useState((patientData && patientData.live) ? valueToSelectObject(patientData.live) : '');
    const [marital, setMarital] = useState((patientData && patientData.marital) ? valueToSelectObject(patientData.marital) : '');
    const [ethnicity, setEthnicity] = useState((patientData && patientData.ethnicity) ? valueToSelectObject(patientData.ethnicity) : '');
    const [ethnicityText, setEthnicityText] = useState((patientData && patientData.ethnicityText && String(patientData.ethnicityText)) || '');
    const [education, setEducation] = useState((patientData && patientData.education) ? valueToSelectObject(patientData.education) : '');
    const [educationText, setEducationText] = useState((patientData && patientData.educationText && String(patientData.educationText)) || '');
    const [familyCare, setFamilyCare] = useState((patientData && patientData.familyCare) ? valueToSelectObject(patientData.familyCare) : '');
    const [careFamily, setCareFamily] = useState((patientData && patientData.careFamily) ? valueToSelectObject(patientData.careFamily) : '');
    const [placeResidence, setPlaceResidence] = useState((patientData && patientData.placeResidence) ? valueToSelectObject(patientData.placeResidence) : '');
    const [placeResidenceText, setPlaceResidenceText] = useState((patientData && patientData.placeResidenceText && String(patientData.placeResidenceText)) || '');
    const [patientDiagnosis, setPatientDiagnosis] = useState((patientData && patientData.patientDiagnosis && String(patientData.patientDiagnosis)) || '');
    const [otherDiagnosis, setOtherDiagnosis] = useState((patientData && patientData.otherDiagnosis && String(patientData.otherDiagnosis)) || '');
    const [careAge, setCareAge] = useState((patientData && patientData.careAge && String(patientData.careAge)) || '');
    const [careGender, setCareGender] = useState((patientData && patientData.careGender) ? valueToSelectObject(patientData.careGender) : '');
    const [careMarital, setCareMarital] = useState((patientData && patientData.careMarital) ? valueToSelectObject(patientData.careMarital) : '');
    const [careEthnicity, setCareEthnicity] = useState((patientData && patientData.careEthnicity) ? valueToSelectObject(patientData.careEthnicity) : '');
    const [careEthnicityText, setCareEthnicityText] = useState((patientData && patientData.careEthnicityText && String(patientData.careEthnicityText)) || '');
    const [careEducation, setCareEducation] = useState((patientData && patientData.careEducation) ? valueToSelectObject(patientData.careEducation) : '');
    const [careEducationText, setCareEducationText] = useState((patientData && patientData.careEducationText && String(patientData.careEducationText)) || '');
    const [careRelationship, setCareRelationship] = useState((patientData && patientData.careRelationship) ? valueToSelectObject(patientData.careRelationship) : '');
    const [careRelationshipText, setCareRelationshipText] = useState((patientData && patientData.careRelationshipText && String(patientData.careRelationshipText)) || '');

    /* const [religion, setReligion] = useState((patientData && patientData.religion) ? valueToSelectObject(patientData.religion) : '');
    const [condition, setCondition] = useState((patientData && patientData.condition) || '');
    const [coMorbidities, setCoMorbidities] = useState((patientData && patientData['co_morbidities'] && patientData['co_morbidities']) ? valuesToSelectArray(patientData['co_morbidities']) : []);
    const [nationality, setNationality] = useState((patientData && patientData.nationality) || '');
    const [zone, setZone] = useState((patientData && patientData.zone) || '');
    const [tinBlock, setTinBlock] = useState((patientData && patientData.tinBlock) || '');
    const [villageHealthTeamResp, setVillageHealthTeamResp] = useState((patientData && patientData.village) || '');
    const [language, setLanguage] = useState((patientData && patientData.language) ? valueToSelectObject(patientData.language) : ''); */

    // Edit patient alert
    const [showAlert, setShowAlert] = useState(false);
    const validationMessage = "The patient could not be modified";
    const EditPatientAlert = <Alert className="m" variant='danger'>{validationMessage}</Alert>;

    const handleCancel = () => {
        history.replace({
            pathname: '/patients',
            state: { patientId: null }
        })
        /*location.state.patientId = null;
        history.goBack();*/
    }

    const PatientNotFound = () => {
        return (
            <div className="mb-4 text-center">
                <h2>
                    {`Patient with ID ${patientId} not found`}
                </h2>

                <h5>
                    Please, if you want to edit the patient demographics use the
                    {<Link to="/patients"> Patients table </Link>}
                </h5>
            </div>
        );
    };
    const handleSubmit = e => {
        e.preventDefault();
        const data = {
            age: age || '',
            gender: selectObjectToValue(gender) || '',
            live: selectObjectToValue(live) || '',
            marital: selectObjectToValue(marital) || '',
            ethnicity: selectObjectToValue(ethnicity) || '',
            ethnicityText: ethnicityText || '',
            education: selectObjectToValue(education) || '',
            educationText: educationText || '',
            familyCare: selectObjectToValue(familyCare) || '',
            careFamily: selectObjectToValue(careFamily) || '',
            placeResidence: selectObjectToValue(placeResidence) || '',
            placeResidenceText: placeResidenceText || '',
            patientDiagnosis: patientDiagnosis || '',
            otherDiagnosis: otherDiagnosis || '',
            careAge: careAge || '',
            careGender: selectObjectToValue(careGender) || '',
            careMarital: selectObjectToValue(careMarital) || '',
            careEthnicity: selectObjectToValue(careEthnicity) || '',
            careEthnicityText: careEthnicityText || '',
            careEducation: selectObjectToValue(careEducation) || '',
            careEducationText: careEducationText || '',
            careRelationship: selectObjectToValue(careRelationship) || '',
            careRelationshipText: careRelationshipText || '',
            /* religion: selectObjectToValue(religion) || '',
            condition,
            "co_morbidities": selectArrayToValues(coMorbidities),
            nationality: nationality || '',
            zone: zone || '',
            tin: tinBlock || '',
            village: villageHealthTeamResp || '',
            language: selectObjectToValue(language) || '' */
        };

        // Using the patients service
        PatientsService.updatePatient(patientId, data)
            .then(_ => {
                setRedirect(true);
            }).catch(_ => {
                setShowAlert(true);
            });
    }

    if (!isLoggedIn)
        return <Redirect to="/login" />

    return (
        redirect ?
            <Redirect
                push
                to={{
                    pathname: "/patients",
                    state: { patientId }
                }} /> :
            <>
                <CustomNavbar />
                {!patientData ?
                    <PatientNotFound /> :
                    <Container>
                        <h2 className="mb-4 text-center">
                            {`Editing patient ${patientId}`}
                        </h2>
                        <Form onSubmit={handleSubmit}>
                            {showAlert && (
                                <Row className="mb-4">
                                    <Col>
                                        {EditPatientAlert}
                                    </Col>
                                </Row>
                            )}
                            <Form.Group controlId="formPatientId">
                                <Form.Row className="justify-content-center">
                                    <Form.Label column sm={2}>Patient ID</Form.Label>
                                    <Col sm={6}>
                                        <Form.Control
                                            disabled
                                            required
                                            type="text" value={patientId}
                                        />
                                        <Form.Text id="patientIdHelpBlock" muted>
                                            <Row>
                                                <Col>
                                                    <FcInfo className="pr-1" size="1.25rem" />
                                                    This id was automatically generated with the patient account
                                                    creation.

                                                </Col>
                                            </Row>
                                        </Form.Text>
                                    </Col>
                                </Form.Row>
                            </Form.Group>

                            <Form.Group controlId="formAge">
                                <Form.Row className="justify-content-center">
                                    <Form.Label column sm={2}>Age</Form.Label>
                                    <Col sm={6}>
                                        <Form.Control
                                            autoFocus type="number" value={age}
                                            onChange={e => setAge(e.target.value)}
                                        />
                                    </Col>
                                </Form.Row>
                            </Form.Group>

                            <Form.Group controlId="formGender">
                                <Form.Row className="justify-content-center">
                                    <Form.Label column sm={2}>Gender</Form.Label>
                                    <Col sm={6}>
                                        <Select
                                            placeholder="Select one..."
                                            defaultValue={gender}
                                            onChange={setGender}
                                            options={demographics.genderOptions}
                                        />
                                    </Col>
                                </Form.Row>
                            </Form.Group>

                            <Form.Group controlId="formLive">
                                <Form.Row className="justify-content-center">
                                    <Form.Label column sm={2}>Do you live alone?</Form.Label>
                                    <Col sm={6}>
                                        <Select
                                            placeholder="Select one..."
                                            defaultValue={live}
                                            onChange={setLive}
                                            options={demographics.liveOptions}
                                        />
                                    </Col>
                                </Form.Row>
                            </Form.Group>

                            <Form.Group controlId="formMarital">
                                <Form.Row className="justify-content-center">
                                    <Form.Label column sm={2}>What is your marital status?</Form.Label>
                                    <Col sm={6}>
                                        <Select
                                            placeholder="Select one..."
                                            defaultValue={marital}
                                            onChange={setMarital}
                                            options={demographics.maritalOptions}
                                        />
                                    </Col>
                                </Form.Row>
                            </Form.Group>

                            <Form.Group controlId="formEthnicity">
                                <Form.Row className="justify-content-center">
                                    <Form.Label column sm={2}>Ethnicity</Form.Label>
                                    <Col sm={6}>

                                        <Select
                                            placeholder="Select one..."
                                            defaultValue={ethnicity}
                                            onChange={setEthnicity}
                                            options={demographics.ethnicityOptions}
                                        />
                                        {ethnicity.label === demographics.ethnicityOptions[demographics.ethnicityOptions.length - 1].label &&
                                            <Form.Control
                                                style={{ "marginTop": "1rem" }}
                                                defaultValue={ethnicityText}
                                                autoFocus type="text" value={ethnicityText}
                                                onChange={e => setEthnicityText(e.target.value)}
                                            />}
                                    </Col>
                                </Form.Row>
                            </Form.Group>

                            <Form.Group controlId="formEducation">
                                <Form.Row className="justify-content-center">
                                    <Form.Label column sm={2}>Highest level of education</Form.Label>
                                    <Col sm={6}>
                                        <Select
                                            placeholder="Select one..."
                                            defaultValue={education}
                                            onChange={setEducation}
                                            options={demographics.educationOptions}
                                        />
                                        {education.label === demographics.ethnicityOptions[demographics.ethnicityOptions.length - 1].label &&
                                            <Form.Control
                                                style={{ "marginTop": "1rem" }}
                                                defaultValue={educationText}
                                                autoFocus type="text" value={educationText}
                                                onChange={e => setEducationText(e.target.value)}
                                            />}
                                    </Col>
                                </Form.Row>
                            </Form.Group>

                            <Form.Group controlId="formFamilyCare">
                                <Form.Row className="justify-content-center">
                                    <Form.Label column sm={2}>Do you have a family member or friend who cares for you?</Form.Label>
                                    <Col sm={6}>
                                        <Select
                                            placeholder="Select one..."
                                            defaultValue={familyCare}
                                            onChange={setFamilyCare}
                                            options={demographics.familyCareOptions}
                                        />
                                    </Col>
                                </Form.Row>
                            </Form.Group>

                            <Form.Group controlId="formCareFamily">
                                <Form.Row className="justify-content-center">
                                    <Form.Label column sm={2}>Do you currently care for a family member(s) or a friend yourself?</Form.Label>
                                    <Col sm={6}>
                                        <Select
                                            placeholder="Select one..."
                                            defaultValue={careFamily}
                                            onChange={setCareFamily}
                                            options={demographics.careFamilyOptions}
                                        />
                                    </Col>
                                </Form.Row>
                            </Form.Group>

                            <Form.Group controlId="formPlaceResidence">
                                <Form.Row className="justify-content-center">
                                    <Form.Label column sm={2}>Place of residence</Form.Label>
                                    <Col sm={6}>
                                        <Select
                                            placeholder="Select one..."
                                            defaultValue={placeResidence}
                                            onChange={setPlaceResidence}
                                            options={demographics.placeOptions}
                                        />
                                        {placeResidence.label === demographics.ethnicityOptions[demographics.ethnicityOptions.length - 1].label &&
                                            <Form.Control
                                                style={{ "marginTop": "1rem" }}
                                                defaultValue={placeResidenceText}
                                                autoFocus type="text" value={placeResidenceText}
                                                onChange={e => setPlaceResidenceText(e.target.value)}
                                            />}
                                    </Col>
                                </Form.Row>
                            </Form.Group>

                            <Form.Group controlId="formPatientDiagnosis">
                                <Form.Row className="justify-content-center">
                                    <Form.Label column sm={2}>Patient's main diagnosis</Form.Label>
                                    <Col sm={6}>
                                        <Form.Control
                                            autoFocus type="text" value={patientDiagnosis}
                                            onChange={e => setPatientDiagnosis(e.target.value)}
                                        />
                                    </Col>
                                </Form.Row>
                            </Form.Group>

                            <Form.Group controlId="formOtherDiagnosis">
                                <Form.Row className="justify-content-center">
                                    <Form.Label column sm={2}>Other diagnosis</Form.Label>
                                    <Col sm={6}>
                                        <Form.Control
                                            autoFocus type="text" value={otherDiagnosis}
                                            onChange={e => setOtherDiagnosis(e.target.value)}
                                        />
                                    </Col>
                                </Form.Row>
                            </Form.Group>


                            <Form.Group controlId="careAge">
                                <Form.Row className="justify-content-center">
                                    <Form.Label column sm={2}>Caregiver age</Form.Label>
                                    <Col sm={6}>
                                        <Form.Control
                                            autoFocus type="number" value={careAge}
                                            onChange={e => setCareAge(e.target.value)}
                                        />
                                    </Col>
                                </Form.Row>
                            </Form.Group>

                            <Form.Group controlId="formCareGender">
                                <Form.Row className="justify-content-center">
                                    <Form.Label column sm={2}>Caregiver gender</Form.Label>
                                    <Col sm={6}>
                                        <Select
                                            placeholder="Select one..."
                                            defaultValue={careGender}
                                            onChange={setCareGender}
                                            options={demographics.genderOptions}
                                        />
                                    </Col>
                                </Form.Row>
                            </Form.Group>

                            <Form.Group controlId="formCareMarital">
                                <Form.Row className="justify-content-center">
                                    <Form.Label column sm={2}>Caregiver marital status</Form.Label>
                                    <Col sm={6}>
                                        <Select
                                            placeholder="Select one..."
                                            defaultValue={careMarital}
                                            onChange={setCareMarital}
                                            options={demographics.maritalOptions}
                                        />
                                    </Col>
                                </Form.Row>
                            </Form.Group>

                            <Form.Group controlId="formCareEthnicity">
                                <Form.Row className="justify-content-center">
                                    <Form.Label column sm={2}>Caregiver ethnicity</Form.Label>
                                    <Col sm={6}>

                                        <Select
                                            placeholder="Select one..."
                                            defaultValue={careEthnicity}
                                            onChange={setCareEthnicity}
                                            options={demographics.ethnicityOptions}
                                        />
                                        {careEthnicity.label === demographics.ethnicityOptions[demographics.ethnicityOptions.length - 1].label &&
                                            <Form.Control
                                                style={{ "marginTop": "1rem" }}
                                                defaultValue={careEthnicityText}
                                                autoFocus type="text" value={careEthnicityText}
                                                onChange={e => setCareEthnicityText(e.target.value)}
                                            />}
                                    </Col>
                                </Form.Row>
                            </Form.Group>

                            <Form.Group controlId="formCareEducation">
                                <Form.Row className="justify-content-center">
                                    <Form.Label column sm={2}>Caregiver highest level of education</Form.Label>
                                    <Col sm={6}>
                                        <Select
                                            placeholder="Select one..."
                                            defaultValue={careEducation}
                                            onChange={setCareEducation}
                                            options={demographics.educationOptions}
                                        />
                                        {careEducation.label === demographics.relationshipOptions[demographics.relationshipOptions.length - 1].label &&
                                            <Form.Control
                                                style={{ "marginTop": "1rem" }}
                                                defaultValue={careEducationText}
                                                autoFocus type="text" value={careEducationText}
                                                onChange={e => setCareEducationText(e.target.value)}
                                            />}
                                    </Col>
                                </Form.Row>
                            </Form.Group>

                            <Form.Group controlId="formCareRelationship">
                                <Form.Row className="justify-content-center">
                                    <Form.Label column sm={2}>Caregiver relationship to patient</Form.Label>
                                    <Col sm={6}>
                                        <Select
                                            placeholder="Select one..."
                                            defaultValue={careRelationship}
                                            onChange={setCareRelationship}
                                            options={demographics.relationshipOptions}
                                        />
                                        {(careRelationship.label === (demographics.relationshipOptions[demographics.relationshipOptions.length - 1].label) || careRelationship.label === (demographics.relationshipOptions[0].label)) &&
                                            <Form.Control
                                                style={{ "marginTop": "1rem" }}
                                                defaultValue={careRelationshipText}
                                                autoFocus type="text" value={careRelationshipText}
                                                onChange={e => setCareRelationshipText(e.target.value)}
                                            />}
                                    </Col>
                                </Form.Row>
                            </Form.Group>





                            <Form.Group controlId="formButtons" className="pt-4">
                                <Form.Row className="text-right">
                                    <Col sm={8}>
                                        <Button className="my-1" variant="secondary" size="lg" onClick={handleCancel}>
                                            Cancel
                                        </Button>
                                    </Col>

                                    <Col sm={2} className="">
                                        <Button className="my-1" variant="primary" type="submit" size="lg">
                                            Save
                                        </Button>
                                    </Col>
                                </Form.Row>
                            </Form.Group>

                        </Form>
                    </Container>
                }

            </>
    )
}

export default EditPatientDemographics;
