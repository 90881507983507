import React from "react";
import {Card, Container} from "react-bootstrap";
import PropTypes from 'prop-types';
import './CustomCard.css'

const defaultStyle = {height: '12rem', width: '18rem'};
/**
 * Component to create custom cards.
 *
 * @component
 *
 * @param {object} props.children: content of the card
 * @param {object} props.style: style of the card
 *
 * @example
 * const children = <Card.Body> ... </Card.Body>;
 * const style = {height: '18rem', width: '18rem'};
 *
 * <CustomCard style={style}> {children} </CustomCard>
 */

const CustomCard = props => {
    const {children, style} = props;
    return (
        <Container className="h-100">
            <Card
                border="secondary"
                className="rounded w-100 h-100"
                text="dark"
                style={style}>
                {children}
            </Card>
        </Container>
    )
}

CustomCard.propTypes = {
    children: PropTypes.array.isRequired,
    style: PropTypes.object
}

CustomCard.defaultProps = {
    style: defaultStyle
};

export default CustomCard;
