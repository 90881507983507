const actionsNames = {
    '0': {
        value: 0,
        name: 'Nothing',
        label: 'Nothing',
    },
    '1': {
        value: 1,
        name: 'Discuss with another health professional',
        label: 'Discuss',
    },
    '2': {
        value: 2,
        name: 'Contact the patient',
        label: 'Contact',
    },
    '3': {
        value: 3,
        name: 'Other - please specify',
        label: 'Other',
    },
    'Nothing': {
        value: 0,
        name: 'Nothing',
        label: 'Nothing',
    },
    'Discuss': {
        value: 1,
        name: 'Discuss with another health professional',
        label: 'Discuss',
    },
    'Contact': {
        value: 2,
        name: 'Contact the patient',
        label: 'Contact',
    },
    'Other': {
        value: 3,
        name: 'Other - please specify',
        label: 'Other',
    },
}

export default actionsNames