import React from 'react'
import { Button, Container, Form, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { CSVLink } from "react-csv";

const DownloadPatientsCSVModal = ({ handleShow, showModal = false, title, description, csvData, handleDownloadValue }) => {

    const handleClose = () => {
        handleShow(false)
    }

    return (
        <Modal
            size='lg'
            show={showModal}
            onHide={handleClose}
            backdrop='static'
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Form>
                <Modal.Body>
                    <Container>
                        {description}
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <CSVLink data={csvData} filename='labels_table.csv'><Button onClick={() => { handleClose() }}>Export</Button></CSVLink>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

DownloadPatientsCSVModal.propTypes = {
    handleShow: PropTypes.func.isRequired,
    handleDownloadValue: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
}

export default DownloadPatientsCSVModal;