import {LOGIN_URL} from "../constants/myApiUrls";
import {MyAxiosInstance} from "../../utils";

/**
 * Perform a POST request to the Login URL with the given data.
 * @param {object} params: data to send to the server
 */
const login = params => MyAxiosInstance.post(LOGIN_URL, params);

export default {
    login
};