import {Spinner} from "react-bootstrap";
import React from "react";

const BasicSpinner = () => (
    <Spinner
        animation="border"
        role="status"
        variant="primary">
        <span className="sr-only">Loading...</span>
    </Spinner>
);

export default BasicSpinner