import React from 'react'
import {Button, Container, Form, Modal} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { deleteIposDataAction } from 'reducers/iposDataReducer';

/**
 * Component to show a modal with different options for delete covid row
 *
 * @component
 *
 * @param {string} dataId
 * @param {function} handleShow: tell the parent component if the modal needs to be shown
 * @param {function} handleSendingPerformed: tell the parent component the result of sending the reminder
 * @param showModal: if true, the modal is shown
 *
 * @example
 * const handleShow = show => setShowModal(show);
 * const handleSendingPerformed = satisfactory => setAlert(satisfactory);
 * const showModal = true;
 * const handleCreate = () => setShowModal(false);
 * <SendReminderModal patientId={patientId} handleShow={handleShow}
 * handleSendingPerformed={handleSendingPerformed} showModal={true} custom={true}/>
 */

 const DeleteModal = ({dataId, handleShow, showModal = false, title, description, handleSendingPerformed}) => {

	const dispatch = useDispatch()

	const handleClose = () => {
			handleShow(null)
	}

	const handleDelete = async (e) => {
		e.preventDefault()
		try {
			dispatch(deleteIposDataAction(dataId))
			//TODO: notifications should have their own reducer
			handleSendingPerformed(true, false);
		} catch (error) {
				handleSendingPerformed(false, false);
				console.error(error)
			}
	}

	return (
			<Modal
					size='lg'
					show={showModal}
					onHide={handleClose}
					backdrop='static'
					aria-labelledby='contained-modal-title-vcenter'
					centered
			>
					<Modal.Header closeButton>
							<Modal.Title>{title}</Modal.Title>
					</Modal.Header>
					<Form onSubmit={handleDelete}>
							<Modal.Body>
									<Container>
											{description}
									</Container>
							</Modal.Body>
							<Modal.Footer>
									<Button variant="secondary" onClick={handleClose}>
											Cancel
									</Button>
									<Button variant="danger" type='submit'>
											Delete
									</Button>
							</Modal.Footer>
					</Form>
			</Modal>
		)
}

DeleteModal.propTypes = {
    dataId: PropTypes.string.isRequired,
    handleShow: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
}

export default DeleteModal;