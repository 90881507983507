import { useEffect, useRef, useState } from "react";
import { useSelector } from 'react-redux'

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import { useMatomo } from "@jonkoops/matomo-tracker-react"

import { Alert, Badge, Col, Container, Dropdown, Row, Spinner } from "react-bootstrap";
import * as badgesColors from "utils/constants/badgesColors";
import actionsNames from 'utils/constants/actionsNames';
import { toLowerKeys, getColumnObj, getColumnObjHidden } from "utils/helperFunctions"
import reportsConstants from "utils/constants/reportsConstants"

import CustomNavbar from "components/Dashboard/Navbar/CustomNavbar";
import { BiMessageAltX, BiMessageCheck } from "react-icons/bi";
import Moment from 'moment';
import { BsDownload, BsPencil, BsThreeDotsVertical, BsTrash } from "react-icons/bs";
import ActionLabel from "components/reusable/ActionLabel";
import filterFactory, { Comparator, selectFilter } from "react-bootstrap-table2-filter";
import paginationFactory, { PaginationListStandalone, PaginationProvider } from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ActionEditModal from "components/reusable/ActionEditModal";
import DeleteModal from "components/reusable/DeleteModal";
import DownloadPDFModal from "components/reusable/DonwloadPDFModal";
import ExportCsv from "./ExportCsv";
import ExportLogsCsv from "./ExportLogsCsv";

export const isEmptyObj = (obj) => {
  if (obj) {
    return Object.keys(obj).length === 0;
  }
  else return true
}

const IposTable = ({ match }) => {
  const [showSatisfactory, setShowSatisfactory] = useState(false);
  const [showNotSatisfactory, setShowNotSatisfactory] = useState(false);
  const [showEditActionSatisfactory, setShowEditActionSatisfactory] = useState(false);
  const [showEditActionNotSatisfactory, setShowEditActionNotSatisfactory] = useState(false);
  const [showDeleteSatisfactory, setShowDeleteSatisfactory] = useState(false);
  const [showDeleteNotSatisfactory, setShowDeleteNotSatisfactory] = useState(false);

  // TODO: This three are storing the row id and using it to render the modals. Should change it to booleans and pass the row id in a different way
  const [showEditIposModal, setShowEditIposModal] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [showDownloadModal, setShowDownloadModal] = useState(null);

  // TODO: Check the use of this, looks like dead code
  const [patientNotFound] = useState([{
    key: "No data available",
    options: "-",
    formattedDate: "-",
    receivedDate: "-",
    formattedReceivedDate: "-",
    patientId: "-",
    infoProvider: "-",
    symptoms: "-",
    otherSymptoms: "-",
    patient_anxiety: "-",
    family_anxiety: "-",
    depression: "-",
    peace: "-",
    feelings: "-",
    info: "-",
    problems: "-",
    completed: "-",
  }]);

  const exportLogsRef = useRef()

  // TODO: Add a "View all patients data button to navigate to /ipos. The router is buggy, if user goes to ipos/:patientId then when clicking on IPOS table link on navbar it continues browsing to the same page of individual patient"
  const patientIdParam = match.params.patientId;

  const iposData = useSelector(({ iposData }) => {
    if (patientIdParam) {
      const singlePatientData = Object.values(iposData.data).filter((patient) => patient.patient_id === patientIdParam)
      return singlePatientData
    }
    else return iposData.data;
  })

  const { trackPageView } = useMatomo()

  useEffect(() => {
    trackPageView()
  }, [trackPageView])

  const { SearchBar } = Search;

  const defaultSorted = [{
    dataField: 'formattedDate',
    order: 'desc'
  }];

  const selectActionOptions = {
    'RE': 'Actioned',
    'UN': 'Unactioned'
  };

  // TODO: Handle alerts in redux
  const cleanAlerts = () => {
    setShowDeleteSatisfactory(false)
    setShowDeleteNotSatisfactory(false)
  }

  const handleShowEditAction = async (patientId) => {
    setShowEditIposModal(patientId);
  }

  const handleShowDelete = patientId => {
    setShowDeleteModal(patientId);
  }

  const handleShowDownloadRow = patientId => {
    setShowDownloadModal(patientId);
  }

  // TODO: Move to notifications reducer
  const handleSendingPerformed = (satisfactory, isEdit) => {
    cleanAlerts()
    if (isEdit) {
      satisfactory ? setShowEditActionSatisfactory(true) : setShowEditActionNotSatisfactory(true)
    } else {
      satisfactory ? setShowDeleteSatisfactory(true) : setShowDeleteNotSatisfactory(true)
    }
    satisfactory ? setShowSatisfactory(true) : setShowNotSatisfactory(true)

    setShowEditIposModal(null);
    setShowDeleteModal(null);

    setTimeout(function () {
      setShowSatisfactory(false)
    }.bind(this), 5000)
  }


  // TODO: Move Badges components to their own file
  const CustomBadge = ({ constant, title }) => (
    <div className="mb-1 text-left">
      <Badge className="mr-2"
        style={{
          fontSize: '14px',
          color: (constant === 2 || constant === '2') ? '#000000' : '#fff',
          //    background: badgesColors[constant],
          background: badgesColors.defaultBadgesColors[constant]
        }}>
        {constant}
      </Badge>
      {title}
    </div>
  );

  const CustomAloneBadge = ({ value, isDefault }) => value !== undefined ?
    <Badge style={{
      fontSize: '14px',
      color: (value === 2 || value === '2') ? '#000000' : '#fff',
      backgroundColor: isDefault ? badgesColors.defaultBadgesColors[value] : badgesColors.reverseBadgesColors[value]
    }}>
      {value}
    </Badge> :
    <div>
      -
    </div>
    ;

  // * getOtherSymptomsString func was not being used and has been removed

  const getOtherSymptomsColumn = (ipos) => {
    let rowList = [];
    for (let i = 1; i < 4; i++) {
      const text = (ipos[`pos_othersym_text_${i}_patient`] != undefined ? ipos[`pos_othersym_text_${i}_patient`] : ipos[`pos_othersym_text_${i}_caregiver`]);
      const value = (ipos[`pos_othersym_value_${i}_patient`] != undefined ? ipos[`pos_othersym_value_${i}_patient`] : ipos[`pos_othersym_value_${i}_caregiver`]);
      if (true) {
        rowList.push(
          <Row key={i}>
            <Col>
              <CustomBadge constant={value} title={text} />
            </Col>
          </Row>
        )
      }
    }
    return rowList.length > 0 ? rowList : <div>-</ div >;
  }

  return (
    <>
      <CustomNavbar />
      <h1 className='text-center mb-3'>IPOS table</h1>
      {/* TODO: Move alerts stuff out of here */}
      {showSatisfactory && (
        <Row className="text-center">
          <Col>
            <Alert
              className="w-50 mx-auto rounded-pill"
              variant="success"
              onClose={() => {
                cleanAlerts()
                setShowSatisfactory(false)
              }}
              dismissible
            >
              <BiMessageCheck size="2rem" />
              {showEditActionSatisfactory && 'The action was successfully edited'}
              {showDeleteSatisfactory && 'The IPOS was successfully deleted'}
            </Alert>
          </Col>
        </Row>
      )}

      {/* TODO: Move alerts stuff out of here */}
      {showNotSatisfactory && (
        <Row className="text-center">
          <Col>
            <Alert
              className="w-50 mx-auto rounded-pill"
              variant="danger"
              onClose={() => {
                cleanAlerts()
                setShowNotSatisfactory(false)
              }}
              dismissible
            >
              <BiMessageAltX size="2rem" />
              {showEditActionNotSatisfactory && 'The action could not be edited. Please, try again later.'}
              {showDeleteNotSatisfactory && 'The IPOS could not be deleted. Please, try again later.'}
            </Alert>
          </Col>
        </Row>
      )}

      <Container fluid>
        <ExportCsv />
        <ExportLogsCsv ref={exportLogsRef} />
        <ToolkitProvider
          keyField='key'
          bootstrap4
          search
          data={iposData ? Object.values(iposData).map(ipos => {
            ipos = toLowerKeys(ipos);
            return {
              key: ipos.id,
              date: ipos.created_at,
              formattedDate: Moment(ipos.created_at).format('DD-MM-YYYY'),
              receivedDate: ipos.created_at,
              formattedReceivedDate: Moment(ipos.received_at).format('DD-MM-YYYY'),
              action: ipos.action == undefined ? '-1' : ipos.action,
              comment: ipos.comment,
              patientId: ipos.patient_id,
              infoProvider:
                (<Col>
                  <Row>{badgesColors.tableIposInfoProvider[ipos.pos_info_pov]}</Row>
                </Col>),
              main: (
                <h6 style={{ wordBreak: "break-word", width: "300px" }}>
                  {ipos.pos_mainproblems_patient || ipos.pos_mainproblems_caregiver}
                </h6>),
              symptoms:
                (
                  <>
                    <Row>
                      <Col><CustomBadge constant={ipos.pos_pain_patient !== undefined ? ipos.pos_pain_patient : ipos.pos_pain_caregiver}
                        title={reportsConstants['POS_Pain']} /></Col>
                    </Row>
                    <Row>
                      <Col><CustomBadge constant={ipos.pos_breathshortness_patient !== undefined ? ipos.pos_breathshortness_patient : ipos.pos_breathshortness_caregiver}
                        title={reportsConstants['POS_BreathShortness']} /></Col>
                    </Row>

                    <Row>
                      <Col><CustomBadge constant={ipos.pos_weaklackenergy_patient !== undefined ? ipos.pos_weaklackenergy_patient : ipos.pos_weaklackenergy_caregiver}
                        title={reportsConstants['POS_WeaknessLackEnergy']} /></Col>
                    </Row>

                    <Row>
                      <Col><CustomBadge constant={ipos.pos_nausea_patient !== undefined ? ipos.pos_nausea_patient : ipos.pos_nausea_caregiver}
                        title={reportsConstants['POS_Nausea']} /></Col>
                    </Row>

                    <Row>
                      <Col><CustomBadge constant={ipos.pos_vomiting_patient !== undefined ? ipos.pos_vomiting_patient : ipos.pos_vomiting_caregiver}
                        title={reportsConstants['POS_Vomiting']} /></Col>
                    </Row>

                    <Row>
                      <Col><CustomBadge constant={ipos.pos_poorappetite_patient !== undefined ? ipos.pos_poorappetite_patient : ipos.pos_poorappetite_caregiver}
                        title={reportsConstants['POS_PoorAppetite']} /></Col>
                    </Row>

                    <Row>
                      <Col><CustomBadge constant={ipos.pos_constipation_patient !== undefined ? ipos.pos_constipation_patient : ipos.pos_constipation_caregiver}
                        title={reportsConstants['POS_Constipation']} /></Col>
                    </Row>

                    <Row>
                      <Col><CustomBadge constant={ipos.pos_sore_patient !== undefined ? ipos.pos_sore_patient : ipos.pos_sore_caregiver}
                        title={reportsConstants['POS_Sore']} /></Col>
                    </Row>

                    <Row>
                      <Col><CustomBadge constant={ipos.pos_drowsiness_patient !== undefined ? ipos.pos_drowsiness_patient : ipos.pos_drowsiness_caregiver}
                        title={reportsConstants['POS_Drowsiness']} /></Col>
                    </Row>

                    <Row>
                      <Col><CustomBadge constant={ipos.pos_poormobility_patient !== undefined ? ipos.pos_poormobility_patient : ipos.pos_poormobility_caregiver}
                        title={reportsConstants['POS_PoorMobility']} /></Col>
                    </Row>
                  </>
                ),
              otherSymptoms: getOtherSymptomsColumn(ipos),
              patient_anxiety: <CustomAloneBadge value={ipos.pos_concern_patient !== undefined ? ipos.pos_concern_patient : ipos.pos_concern_caregiver} isDefault={true} />,
              family_anxiety: <CustomAloneBadge value={ipos.pos_feelings_patient !== undefined ? ipos.pos_feelings_patient : ipos.pos_feelings_caregiver} isDefault={true} />,
              depression: <CustomAloneBadge value={ipos.pos_lifeworthwhile_patient !== undefined ? ipos.pos_lifeworthwhile_patient : ipos.pos_lifeworthwhile_caregiver} isDefault={true} />,
              peace: <CustomAloneBadge value={ipos.pos_peacefeeling_patient !== undefined ? ipos.pos_peacefeeling_patient : ipos.pos_peacefeeling_caregiver} isDefault={true} />,
              feelings: <CustomAloneBadge value={ipos.pos_help_patient !== undefined ? ipos.pos_help_patient : ipos.pos_help_caregiver} isDefault={true} />,
              info: <CustomAloneBadge value={ipos.pos_info_patient !== undefined ? ipos.pos_info_patient : ipos.pos_info_caregiver} isDefault={true} />,
              problems: <CustomAloneBadge value={ipos.pos_problems_patient != undefined ? ipos.pos_problems_patient : ipos.pos_problems_caregiver} isDefault={true} />,
              completed: (ipos.pos_completion_patient || ipos.pos_completion_caregiver) === "0" ? "On my own" :
                (ipos.pos_completion_patient || ipos.pos_completion_caregiver) === "1" ? "Friend or relative" :
                  (ipos.pos_completion_patient || ipos.pos_completion_caregiver) === "2" ? "Member of staff" : "-"
              ,
              options:
                <Dropdown>
                  <Dropdown.Toggle variant="primary">
                    <BsThreeDotsVertical size="1rem" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu align="left">
                    <Dropdown.Item
                      // TODO: Implement handlers
                      onClick={() => handleShowEditAction(ipos.id)}
                    >
                      <BsPencil className="mr-2" />
                      Actions
                    </Dropdown.Item>
                    <Dropdown.Item
                      // TODO: Implement handlers
                      onClick={() => handleShowDownloadRow(ipos.id)}
                    >
                      <BsDownload className="mr-2" />
                      Download
                    </Dropdown.Item>
                    <Dropdown.Item
                      // TODO: Implement handlers
                      onClick={() => handleShowDelete(ipos.id)}
                      style={{ color: 'red' }}>
                      <BsTrash color='red' className='mr-2' />
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
            }
          })
            : patientNotFound
          }
          columns={[
            getColumnObjHidden('patientId', " #", '-', true, true),
            getColumnObj(
              'key',
              '#'
            ),
            getColumnObj('options', 'Options'),

            getColumnObj(
              'formattedDate',
              'Submitted On',
              undefined,
              (a, b, order, dateField, rowA, rowB) => order === 'asc' ?
                rowA.date.localeCompare(rowB.date) : rowB.date.localeCompare(rowA.date)
            ),
            getColumnObj(
              'formattedReceivedDate',
              'Received On',
              undefined,
              (a, b, order, dateField, rowA, rowB) => order === 'asc' ?
                rowA.date.localeCompare(rowB.receivedDate) : rowB.date.localeCompare(rowA.receivedDate)
            ),

            getColumnObj(
              'action',
              'Action',
              (cell, row) =>
                <>
                  {row.action && row.action != "-1" ? (
                    <Row>
                      <Col>
                        <ActionLabel
                          title={actionsNames[row.action].label}
                          // TODO: implement handlers
                          handleClick={() => handleShowEditAction(row.key)}
                        />
                      </Col>
                    </Row>
                  ) :
                    (<Row>
                      <Col>
                        -
                      </Col>
                    </Row>)}
                </>,
              (a, b, order, dateField, rowA, rowB) => order === 'asc' ?
                rowA.action.localeCompare(rowB.action) : rowB.action.localeCompare(rowA.action),
              true,
              selectFilter({
                placeholder: "Select Actioned/Unactioned reports...",
                options: selectActionOptions,
                className: 'm-1',
                comparator: Comparator.LIKE,
              }),
              (cell, row) => `${row.action !== "-1" ? 'RE' : 'UN'}  ${cell}`
            ),
            getColumnObj(
              'patientId',
              'Patient ID',
              (cell, row) =>
                <>
                  <Row>
                    <Col>
                      {row.patientId}
                    </Col>
                  </Row>
                </>,
            ),
            getColumnObj('infoProvider', 'Info Provider'),
            getColumnObj("main", 'Main Problems', cell => cell || '-'),
            getColumnObj('symptoms', 'Symptoms', undefined, undefined, false, undefined, undefined, false, 'text-nowrap col-xs-auto'),
            getColumnObj('otherSymptoms', 'Other Symptoms', undefined, undefined, false, undefined, undefined, false, 'text-nowrap col-xs-auto'),
            getColumnObj('patient_anxiety', 'Patient Anxiety'),
            getColumnObj('family_anxiety', 'Family Anxiety'),
            getColumnObj('depression', 'Feeling depressed'),
            getColumnObj('peace', 'At Peace'),
            getColumnObj('feelings', 'Share Feelings'),
            getColumnObj('info', 'Information'),
            getColumnObj('problems', 'Practical problems'),
            getColumnObj('completed', 'Completed by'),
          ]}
        >
          {props =>
            <div>
              <div className="my-3" />
              <SearchBar className="my-3"
                size="lg"
                placeholder="Search by Patient ID..." {...props.searchProps}
              />

              <PaginationProvider pagination={paginationFactory({
                custom: true,
                totalSize: iposData ? Object.keys(iposData).length : 1,
                sizePerPage: 5
              })}>
                {
                  ({
                    paginationProps,
                    paginationTableProps
                  }) => (
                    <div>
                      <PaginationListStandalone
                        {...paginationProps}
                      />
                      <BootstrapTable
                        defaultSorted={defaultSorted}
                        striped
                        hover
                        bordered
                        wrapperClasses="table-responsive my-3"
                        filter={filterFactory()}
                        {...paginationTableProps}
                        {...props.baseProps}
                      />
                      <PaginationListStandalone
                        {...paginationProps}
                      />

                    </div>
                  )
                }
              </PaginationProvider>
            </div>
          }
        </ToolkitProvider>
      </Container>
      {
        showEditIposModal &&
        <ActionEditModal
          key={showEditIposModal}
          rowId={showEditIposModal}
          handleShow={setShowEditIposModal}
          showModal={showEditIposModal}
          handleSendingPerformed={handleSendingPerformed}
          updateLogExport={exportLogsRef.current.createLogCsvData}
        />
      }
      {
        showDeleteModal &&
        <DeleteModal
          title='Delete IPOS report?'
          description='Are you sure you want to delete this IPOS report?'
          handleSendingPerformed={handleSendingPerformed}
          dataId={showDeleteModal}
          handleShow={handleShowDelete}
          showModal={showDeleteModal}
        />
      }
      {
        showDownloadModal &&
        <DownloadPDFModal
          title='Download the row as PDF'
          handleDownloadValue={() => {
            console.log('sending value')
          }}
          handleShow={handleShowDownloadRow}
          showModal={showDownloadModal}
        />
      }
    </>
  )

}

export default IposTable