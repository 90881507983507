/*export const mockNotifications = [
    "Patient 1: Reported COVID symptoms",
    "Patient 8: Missed a form",
    "Patient 21: Missed a form",
    "Patient 22: Reported COVID symptoms",
    "Patient 31: Missed a form",
    "Patient 53: Reported COVID symptoms",

]*/

export const mockNotifications = {
    1: {
        "id": 1,
        "type": "mpalliative",
        "message": "The patient U000001 did not reply a form in between the scheduled time",
        "created_at": "2021-02-25 08:05:01",
        "updated_at": "2021-02-25 08:05:01"
    },
    2:
        {
            "id": 2,
            "type": "covid-questionnaire",
            "message": "The patient U000005 did not reply a form in between the scheduled time",
            "created_at": "2021-02-25 03:05:01",
            "updated_at": "2021-02-25 03:05:01"
        },
    3:
        {
            "id": 3,
            "type": "action",
            "message": "The report R000004 does not have an action associated",
            "created_at": "2021-02-24 08:05:01",
            "updated_at": "2021-02-24 08:05:01"
        },
    4:
        {
            "id": 4,
            "type": "action",
            "message": "The report R000009 does not have an action associated",
            "created_at": "2021-02-21 08:05:01",
            "updated_at": "2021-02-21 08:05:01"
        },
    5:
        {
            "id": 5,
            "type": "mpalliative",
            "message": "The patient U000009 did not reply a form in between the scheduled time",
            "created_at": "2020-05-25 08:05:01",
            "updated_at": "2020-05-25 08:05:01"
        },
    6:
        {
            "id": 6,
            "type": "mpalliative",
            "message": "The patient U000013 did not reply a form in between the scheduled time",
            "created_at": "2021-01-25 08:05:01",
            "updated_at": "2021-01-25 08:05:01"

        },
    7:
        {
            "id": 7,
            "type": "mpalliative",
            "message": "The patient U000002 did not reply a form in between the scheduled time",
            "created_at": "2020-11-25 08:05:01",
            "updated_at": "2020-11-25 08:05:01"
        }
}