import React, { useState, useEffect } from 'react'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import IposService from '../../utils/services/ReportsService';
import { Link, Redirect } from 'react-router-dom'
import { useAuth } from '../../context/auth'
import CustomNavbar from '../Dashboard/Navbar/CustomNavbar'
import * as badgesColors from "../../utils/constants/badgesColors";
import BadgeColor from './BadgeColor'
import { Container, Dropdown, Spinner, Row, Col, Alert, Button } from 'react-bootstrap';
import { BsPencil, BsTrash, BiMessageAltX, BiMessageCheck, BsThreeDotsVertical } from "react-icons/all";
import Moment from 'moment'
import './SymptomsTable.css';
import BootstrapTable from 'react-bootstrap-table-next'
import DeleteModal from '../reusable/DeleteModal';
import ActionEditModal from '../reusable/ActionEditModal';
import ActionLabel from '../reusable/ActionLabel'
import actionsNames from '../../utils/constants/actionsNames'
import { getColumnObj, getResponseData } from "../../utils/helperFunctions";
import ReportsService from "../../utils/services/ReportsService";
import { CSVLink } from "react-csv";
import filterFactory, { selectFilter, Comparator } from 'react-bootstrap-table2-filter';
import { Status } from "../../utils";
import paginationFactory, { PaginationListStandalone, PaginationProvider } from "react-bootstrap-table2-paginator";
import DownloadCSVModal from '../reusable/DownloadCSVModal';
import BadgeRisks from './BadgeRisks';

const SymptomsTable = ({ match }) => {
    const patientIdParam = match.params.patientId;
    const { isLoggedIn, logout } = useAuth();
    const [actionByReport, setActionByReport] = useState(null);
    const [currentPatient, setCurrentPatient] = useState(null);
    const [symptomsData, setSymptomsData] = useState(null);
    const [isSymptomsData, setIsSymptomsData] = useState(false);
    const [valuesCsvData, setValuesCsvData] = useState([["Report", "Form completion date", "Received date", "Patient ID", "Information Provider", "Information Provider Especification", "Risk Covid", "Risk Tuberculosis", "Risk Hepatitis", "Fever", "Chills", "Night Sweats", "Cough", "Prolonged Cough", "Fatigue", "Headache", "Headache Frequency", "Nausea/Vomiting", "Weight Loss", "Dizziness", "Breath Shortness", "Describe Breathing", "Painful Throat", "Loss of Smell", "Hoarse Voice", "Chest Pain", "Abdominal Pain", "Diarrhoea", "Loose Stools", "Coloured Stools", "Yellowing Skin", "Muscle Pain", "Welts or swelling", "Feet Blisters", "Confusion", "Eye Soreness", "Loss Appetite", "Other Symptoms", "Action", "Action Comment"]]);
    const [labelsCsvData, setLabelsCsvData] = useState([["Report", "Form completion date", "Received date", "Patient ID", "Information Provider", "Information Provider Especification", "Risk Covid", "Risk Tuberculosis", "Risk Hepatitis", "Fever", "Chills", "Night Sweats", "Cough", "Prolonged Cough", "Fatigue", "Headache", "Headache Frequency", "Nausea/Vomiting", "Weight Loss", "Dizziness", "Breath Shortness", "Describe Breathing", "Painful Throat", "Loss of Smell", "Hoarse Voice", "Chest Pain", "Abdominal Pain", "Diarrhoea", "Loose Stools", "Coloured Stools", "Yellowing Skin", "Muscle Pain", "Welts or swelling", "Feet Blisters", "Confusion", "Eye Soreness", "Loss Appetite", "Other Symptoms", "Action", "Action Comment"]]);
    const [logsCsvData, setLogsCsvData] = useState([["ID", "Created At", "User", "Patient ID", "Type", "Log"]]);
    const [isCsvDone, setIsCsvDone] = useState(false);
    const [isLogCsvDone, setIsLogCsvDone] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
    const [comment, setComment] = useState('');

    const [showSatisfactory, setShowSatisfactory] = useState(false);
    const [showNotSatisfactory, setShowNotSatisfactory] = useState(false);

    const [showEditSatisfactory, setShowEditSatisfactory] = useState(false);
    const [showEditNotSatisfactory, setShowEditNotSatisfactory] = useState(false);
    const [showDeleteSatisfactory, setShowDeleteSatisfactory] = useState(false);
    const [showDeleteNotSatisfactory, setShowDeleteNotSatisfactory] = useState(false);

    const [showEditModal, setShowEditModal] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(null);
    const [showDownloadCSVModal, setShowDownloadCSVModal] = useState(null);

    const [patientNotFound, setPatientNotFound] = useState(false);

    const { SearchBar } = Search;

    const defaultSorted = [{
        dataField: 'formattedDate',
        order: 'desc'
    }];

    const selectActionOptions = {
        'RE': 'Read',
        'UN': 'Unread'
    };

    const PatientNotFound = () => {
        return (
            <div className="mb-4 text-center">
                <h2>
                    {`Patient with ID ${patientIdParam} not found`}
                </h2>

                <h5>
                    Please, if you want to see the SYMPTOMS reports of a specific patient use the
                    {<Link to="/patients"> Patients table </Link>}
                </h5>
            </div>
        );
    };

    function onColumnMatch({ searchText, value }) {
        const [isLabelled, patientId] = value.split(' ').map(e => e.toLowerCase())
        return patientId.includes(searchText.toLowerCase()) ||
            (searchText.toLowerCase() === 'labelled' && 'la' === isLabelled) ||
            (searchText.toLowerCase() === 'unlabelled' && 'ua' === isLabelled);
    }

    const handleShowEditAction = async (patientId, action, comment) => {
        setActionByReport(null);
        try {
            //todo
            const resp = await ReportsService.getActionLogsOfReport('symptoms_action', patientId);
            const response = getResponseData(resp)
            const result = Object.keys(response.values).map((key) => response.values[key]);
            setActionByReport(result);
        } catch (err) {
            console.log(err)
        }
        var finalAction = '0';
        var finalComment = '';

        if (action) {
            finalAction = action
        }
        if (comment) {
            finalComment = comment
        }

        setComment(finalComment)
        setSelectedAction(action)
        setShowEditModal(patientId);
    }
    const handleShowDelete = patientId => {
        setShowDeleteModal(patientId);
    }

    const handleShowDownload = mustShow => {
        // console.log(valuesCsvData)
        setShowDownloadCSVModal(mustShow);
    }

    const cleanAlerts = () => {
        setShowEditSatisfactory(false)
        setShowDeleteSatisfactory(false)
        setShowEditNotSatisfactory(false)
        setShowDeleteNotSatisfactory(false)
    }

    const handleSendDelete = async () => {
        //TODO:: api call
        //return true if api call success  -  return false if not
        try {
            const response = await IposService.deleteReport(showDeleteModal);
            if (response.status === 200) {
                handleSendingPerformed(true, false)
                getSymptomsData();
            } else {
                handleSendingPerformed(false, false);
            }
        } catch (e) {
            handleSendingPerformed(false, false);
        }
    }

    const handleSendEdit = async () => {
        // TODO: Api call
        //return true if api call success  -  return false if not
        try {
            var newSymptomsData = symptomsData[showEditModal]
            newSymptomsData.action = selectedAction;
            newSymptomsData.comment = comment;
            newSymptomsData.log_type = 'symptoms_action';

            const response = await IposService.updateSymptoms(newSymptomsData, showEditModal);
            if (response.status === 200) {
                setValuesCsvData([["Report", "Form completion date", "Received date", "Patient ID", "Information Provider", "Information Provider Especification", "Risk Covid", "Risk Tuberculosis", "Risk Hepatitis", "Fever", "Chills", "Night Sweats", "Cough", "Prolonged Cough", "Fatigue", "Headache", "Headache Frequency", "Nausea/Vomiting", "Weight Loss", "Dizziness", "Breath Shortness", "Describe Breathing", "Painful Throat", "Loss of Smell", "Hoarse Voice", "Chest Pain", "Abdominal Pain", "Diarrhoea", "Loose Stools", "Coloured Stools", "Yellowing Skin", "Muscle Pain", "Welts or swelling", "Feet Blisters", "Confusion", "Eye Soreness", "Loss Appetite", "Other Symptoms", "Action", "Action Comment"]])
                setLabelsCsvData([["Report", "Form completion date", "Received date", "Patient ID", "Information Provider", "Information Provider Especification", "Risk Covid", "Risk Tuberculosis", "Risk Hepatitis", "Fever", "Chills", "Night Sweats", "Cough", "Prolonged Cough", "Fatigue", "Headache", "Headache Frequency", "Nausea/Vomiting", "Weight Loss", "Dizziness", "Breath Shortness", "Describe Breathing", "Painful Throat", "Loss of Smell", "Hoarse Voice", "Chest Pain", "Abdominal Pain", "Diarrhoea", "Loose Stools", "Coloured Stools", "Yellowing Skin", "Muscle Pain", "Welts or swelling", "Feet Blisters", "Confusion", "Eye Soreness", "Loss Appetite", "Other Symptoms", "Action", "Action Comment"]])
                handleSendingPerformed(true, true)
                getSymptomsData()
                setIsCsvDone(false);
            } else {
                handleSendingPerformed(false, true)
            }
        } catch (e) {
            handleSendingPerformed(false, true);
        }

    }

    const handleSendingPerformed = (satisfactory, isEdit) => {
        cleanAlerts()
        if (isEdit) {
            satisfactory ? setShowEditSatisfactory(true) : setShowEditNotSatisfactory(true)
        } else {
            satisfactory ? setShowDeleteSatisfactory(true) : setShowDeleteNotSatisfactory(true)
        }
        satisfactory ? setShowSatisfactory(true) : setShowNotSatisfactory(true)
        setShowEditModal(null);
        setShowDeleteModal(null);

        setTimeout(function () {
            setShowSatisfactory(false)
        }.bind(this), 5000)
    }

    const createLogCsvData = async () => {
        setIsLogCsvDone(true);
        try {
            const resp = await ReportsService.getAllActionLogs('symptoms_action');
            const response = getResponseData(resp)
            const results = Object.keys(response.values).map((key) => response.values[key]);

            var newLogCsvData = logsCsvData;
            results.map(result => {
                newLogCsvData = [...newLogCsvData, [
                    result.id,
                    result.created_at,
                    result.user,
                    result.patient_id,
                    result.type,
                    result.log,
                ]]
            })
            setLogsCsvData(newLogCsvData);

        } catch (err) {
            console.log(err)
        }
    }

    const createCsvData = () => {
        var newValuesCsvData = valuesCsvData;
        var newLabelsCsvData = labelsCsvData;

        Object.values(symptomsData).map(symptom => {
            newLabelsCsvData = [...newLabelsCsvData, [
                symptom.id,
                Moment(symptom.received_at).format('DD-MM-YYYY'),
                Moment(symptom.created_at).format('DD-MM-YYYY'),
                symptom.patient_id,
                badgesColors.tableIposInfoProvider[symptom.covid_info_prov],
                symptom.covid_other,
                badgesColors.risksToLabel[symptom.risk_covid.toString()],
                badgesColors.risksToLabel[symptom.risk_tb.toString()],
                badgesColors.risksToLabel[symptom.risk_hepatitis.toString()],
                badgesColors.tableSymptomDefault[symptom.cov_fever],
                badgesColors.tableSymptomDefault[symptom.cov_chills],
                badgesColors.tableSymptomDefault[symptom.cov_nightsweats],
                badgesColors.tableSymptomDefault[symptom.cov_cough],
                symptom.cov_cough === '1' ? badgesColors.tableSymptomDefault[symptom.cov_prolongedcough] : null,
                badgesColors.tableSymptomFatigue[symptom.COV_Fatigue],
                badgesColors.tableSymptomDefault[symptom.cov_headache],
                badgesColors.tableSymptomHeadAches[symptom.cov_headachefrecuency],
                badgesColors.tableSymptomDefault[symptom.cov_nausevomiting],
                badgesColors.tableSymptomDefault[symptom.cov_weightloss],
                badgesColors.tableSymptomDefault[symptom.cov_dizziness],
                badgesColors.tableSymptomDefault[symptom.cov_breathshortness],
                badgesColors.tableSymptomBreath[symptom.cov_describebreathing],
                badgesColors.tableSymptomDefault[symptom.cov_painfulthroat],
                badgesColors.tableSymptomDefault[symptom.cov_losssmell],
                badgesColors.tableSymptomDefault[symptom.cov_hoarsevoice],
                badgesColors.tableSymptomDefault[symptom.cov_chestpain],
                badgesColors.tableSymptomDefault[symptom.cov_abdominalpain],
                badgesColors.tableSymptomDefault[symptom.cov_diarrhoea],
                badgesColors.tableSymptomLooseStools[symptom.cov_loosestools],
                badgesColors.tableSymptomDefault[symptom.cov_colouredstools],
                badgesColors.tableSymptomDefault[symptom.cov_yellowingskin],
                badgesColors.tableSymptomDefault[symptom.cov_musclepain],
                badgesColors.tableSymptomDefault[symptom.cov_weltsswelling],
                badgesColors.tableSymptomDefault[symptom.cov_feetblisters],
                badgesColors.tableSymptomDefault[symptom.cov_confusion],
                badgesColors.tableSymptomDefault[symptom.cov_eyesoreness],
                badgesColors.tableSymptomDefault[symptom.cov_lossappetite],
                symptom.cov_othersymptoms,
                symptom.action ? actionsNames[symptom.action].name : '',
                symptom.comment
            ]]
        })
        Object.values(symptomsData).map(symptom => {
            newValuesCsvData = [...newValuesCsvData, [
                symptom.id,
                Moment(symptom.received_at).format('DD-MM-YYYY'),
                Moment(symptom.created_at).format('DD-MM-YYYY'),
                symptom.patient_id,
                symptom.covid_info_prov,
                symptom.covid_other,
                badgesColors.tableSymptomRiskValue[symptom.risk_covid.toString()],
                badgesColors.tableSymptomRiskValue[symptom.risk_tb.toString()],
                badgesColors.tableSymptomRiskValue[symptom.risk_hepatitis.toString()],
                symptom.cov_fever,
                symptom.cov_chills,
                symptom.cov_nightsweats,
                symptom.cov_cough,
                symptom.cov_prolongedcough,
                symptom.COV_Fatigue,
                symptom.cov_headache,
                symptom.cov_headachefrecuency,
                symptom.cov_nausevomiting,
                symptom.cov_weightloss,
                symptom.cov_dizziness,
                symptom.cov_breathshortness,
                symptom.cov_describebreathing,
                symptom.cov_painfulthroat,
                symptom.cov_losssmell,
                symptom.cov_hoarsevoice,
                symptom.cov_chestpain,
                symptom.cov_abdominalpain,
                symptom.cov_diarrhoea,
                symptom.cov_loosestools,
                symptom.cov_colouredstools,
                symptom.cov_yellowingskin,
                symptom.cov_musclepain,
                symptom.cov_weltsswelling,
                symptom.cov_feetblisters,
                symptom.cov_confusion,
                symptom.cov_eyesoreness,
                symptom.cov_lossappetite,
                symptom.cov_othersymptoms,
                symptom.action ? symptom.action : '',
                symptom.comment
            ]]
        })
        setLabelsCsvData(newLabelsCsvData);
        setValuesCsvData(newValuesCsvData);
        setIsCsvDone(true);
    }

    const getSymptomsData = async () => {
        try {
            let response;
            if (patientIdParam) {
                setCurrentPatient(patientIdParam)
                response = await ReportsService.getPatientSymptoms(patientIdParam);
            } else {
                response = await ReportsService.getSymptoms();
            }
            setSymptomsData(getResponseData(response) || {});
        } catch (error) {
            error.response.status === Status.BAD_REQUEST ? setPatientNotFound(true) : logout();
        }
    };

    useEffect(() => {
        getSymptomsData();
    }, []);

    if (!isCsvDone && symptomsData) {
        createCsvData()
    }
    if (!isLogCsvDone && symptomsData) {
        createLogCsvData()
    }

    if (!patientIdParam && !isSymptomsData) {
        getSymptomsData();
        setIsSymptomsData(true);
    }

    if (patientIdParam != currentPatient && patientIdParam != null) {
        getSymptomsData();
    }

    if (!isLoggedIn)
        return <Redirect to='/login' />

    return (
        <>
            <CustomNavbar />
            {symptomsData && <h1 className='text-center mb-3'>Symptoms table</h1>}

            {showSatisfactory && (
                <Row className="text-center">
                    <Col>
                        <Alert
                            className="w-50 mx-auto rounded-pill"
                            variant="success"
                            onClose={() => {
                                cleanAlerts()
                                setShowSatisfactory(false)
                            }}
                            dismissible
                        >
                            <BiMessageCheck size="2rem" />
                            {showEditSatisfactory && 'The Symptoms report was successfully edited'}
                            {showDeleteSatisfactory && 'The Symptoms report was successfully deleted'}
                        </Alert>
                    </Col>
                </Row>
            )}

            {showNotSatisfactory && (
                <Row className="text-center">
                    <Col>
                        <Alert
                            className="w-50 mx-auto rounded-pill"
                            variant="danger"
                            onClose={() => {
                                cleanAlerts()
                                setShowNotSatisfactory(false)
                            }}
                            dismissible
                        >
                            <BiMessageAltX size="2rem" />
                            {showEditNotSatisfactory && 'The Symptoms report could not be edited. Please, try again later.'}
                            {showDeleteNotSatisfactory && 'The Symptoms report could not be deleted. Please, try again later.'}
                        </Alert>
                    </Col>
                </Row>
            )}

            {patientNotFound && <PatientNotFound />}

            {symptomsData ?
                <Container fluid>
                    <Button className='mr-2' onClick={() => { handleShowDownload(true) }}>Export as CSV</Button>
                    <CSVLink data={logsCsvData} filename='logs_table.csv'><Button>Export Logs</Button></CSVLink>
                    <ToolkitProvider
                        keyField='key'
                        bootstrap4
                        search={{ onColumnMatch }}
                        data={Object.values(symptomsData).map(symptom => {
                            return {
                                key: symptom.id,
                                date: symptom.received_at,
                                formattedDate: Moment(symptom.received_at).format('DD-MM-YYYY'),
                                recievedDate: symptom.created_at,
                                formattedRecieveddate: Moment(symptom.created_at).format('DD-MM-YYYY'),
                                action: symptom.action,
                                comment: symptom.comment,
                                patientId: symptom.patient_id,
                                infoProvider: symptom.covid_info_prov === '3'
                                    ? (<Col>
                                        <Row>{badgesColors.tableIposInfoProvider[symptom.covid_info_prov]}</Row>
                                        <Row>{symptom.pos_other}</Row>
                                    </Col>)
                                    : badgesColors.tableIposInfoProvider[symptom.covid_info_prov],
                                risk: { risk_covid: symptom.risk_covid, risk_tb: symptom.risk_tb, risk_hepatitis: symptom.risk_hepatitis },
                                fever: symptom.cov_fever ? <BadgeColor data={badgesColors.tableSymptomDefault[symptom.cov_fever]} /> : '-',
                                chills: symptom.cov_chills ? <BadgeColor data={badgesColors.tableSymptomDefault[symptom.cov_chills]} /> : '-',
                                nightSweats: symptom.cov_nightsweats ? <BadgeColor data={badgesColors.tableSymptomDefault[symptom.cov_nightsweats]} /> : '-',
                                cough: symptom.cov_cough ? <BadgeColor data={badgesColors.tableSymptomDefault[symptom.cov_cough]} /> : '-',
                                prolongedCough: symptom.cov_prolongedcough ? (symptom.cov_cough === '1' ? <BadgeColor data={badgesColors.tableSymptomDefault[symptom.cov_prolongedcough]} /> : '-') : '-',
                                fatigue: symptom.cov_fatigue ? (symptom.cov_fatigue === '0' ? <BadgeColor data={badgesColors.tableSymptomFatigue[symptom.cov_fatigue]} /> : badgesColors.tableSymptomFatigue[symptom.cov_fatigue]) : '-',
                                headache: symptom.cov_headache ? <BadgeColor data={badgesColors.tableSymptomDefault[symptom.cov_headache]} /> : '-',
                                headacheFrequency: symptom.cov_headachefrecuency ? badgesColors.tableSymptomHeadAches[symptom.cov_headachefrecuency] : '-',
                                nauseaVomiting: symptom.cov_nausevomiting ? <BadgeColor data={badgesColors.tableSymptomDefault[symptom.cov_nausevomiting]} /> : '-',
                                weightLoss: symptom.cov_weightloss ? <BadgeColor data={badgesColors.tableSymptomDefault[symptom.cov_weightloss]} /> : '-',
                                dizziness: symptom.cov_dizziness ? <BadgeColor data={badgesColors.tableSymptomDefault[symptom.cov_dizziness]} /> : '-',
                                breathShortness: symptom.cov_breathshortness ? <BadgeColor data={badgesColors.tableSymptomDefault[symptom.cov_breathshortness]} /> : '-',
                                describeBreathing: symptom.cov_describebreathing ? badgesColors.tableSymptomBreath[symptom.cov_describebreathing] : '-',
                                painfulThroat: symptom.cov_painfulthroat ? <BadgeColor data={badgesColors.tableSymptomDefault[symptom.cov_painfulthroat]} /> : '-',
                                lossSmell: symptom.cov_losssmell ? <BadgeColor data={badgesColors.tableSymptomDefault[symptom.cov_losssmell]} /> : '-',
                                hoarseVoice: symptom.cov_hoarsevoice ? <BadgeColor data={badgesColors.tableSymptomDefault[symptom.cov_hoarsevoice]} /> : '-',
                                chestPain: symptom.cov_chestpain ? <BadgeColor data={badgesColors.tableSymptomDefault[symptom.cov_chestpain]} /> : '-',
                                abdominalPain: symptom.cov_abdominalpain ? <BadgeColor data={badgesColors.tableSymptomDefault[symptom.cov_abdominalpain]} /> : '-',
                                diarrhea: symptom.cov_diarrhoea ? <BadgeColor data={badgesColors.tableSymptomDefault[symptom.cov_diarrhoea]} /> : '-',
                                looseStools: symptom.cov_loosestools ? badgesColors.tableSymptomLooseStools[symptom.cov_loosestools] : '-',
                                colouredStools: symptom.cov_colouredstools ? <BadgeColor data={badgesColors.tableSymptomDefault[symptom.cov_colouredstools]} /> : '-',
                                yellowingSkin: symptom.cov_yellowingskin ? <BadgeColor data={badgesColors.tableSymptomDefault[symptom.cov_yellowingskin]} /> : '-',
                                musclePain: symptom.cov_musclepain ? <BadgeColor data={badgesColors.tableSymptomDefault[symptom.cov_musclepain]} /> : '-',
                                weltsSwelling: symptom.cov_weltsswelling ? <BadgeColor data={badgesColors.tableSymptomDefault[symptom.cov_weltsswelling]} /> : '-',
                                feetBlisters: symptom.cov_feetblisters ? <BadgeColor data={badgesColors.tableSymptomDefault[symptom.cov_feetblisters]} /> : '-',
                                confusion: symptom.cov_confusion ? <BadgeColor data={badgesColors.tableSymptomDefault[symptom.cov_confusion]} /> : '-',
                                eyeSoreness: symptom.cov_eyesoreness ? <BadgeColor data={badgesColors.tableSymptomDefault[symptom.cov_eyesoreness]} /> : '-',
                                lossAppetite: symptom.cov_lossappetite ? <BadgeColor data={badgesColors.tableSymptomDefault[symptom.cov_lossappetite]} /> : '-',
                                otherSymptoms: symptom.cov_othersymptoms || '-',
                                options:
                                    <Dropdown>
                                        <Dropdown.Toggle variant='primary'>
                                            <BsThreeDotsVertical size='1em' />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu align='left'>
                                            <Dropdown.Item
                                                onClick={() => handleShowEditAction(symptom.id, symptom.action, symptom.comment)}>
                                                <BsPencil className='mr-2' />
                                                Actions
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleShowDelete(symptom.id)}
                                                style={{ color: 'red' }}>
                                                <BsTrash color='red' className='mr-2' />
                                                Delete
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                            }
                        })}
                        columns={[
                            getColumnObj(
                                'key',
                                '#'
                            ),
                            getColumnObj(
                                'formattedDate',
                                'Completion Date',
                                undefined,
                                (a, b, order, dateField, rowA, rowB) => order === 'asc' ?
                                    rowA.date.localeCompare(rowB.date) : rowB.date.localeCompare(rowA.date)
                            ),
                            getColumnObj(
                                'formattedRecieveddate',
                                'Received Date',
                            ),
                            getColumnObj(
                                'patientId',
                                'Patient ID',
                                (cell, row) =>
                                    <>
                                        <Row>
                                            <Col>
                                                {row.patientId}
                                            </Col>
                                        </Row>
                                        {row.action && (
                                            <Row>
                                                <Col>
                                                    <ActionLabel
                                                        title={actionsNames[row.action].label}
                                                        handleClick={() => handleShowEditAction(row.key, row.action, row.comment)} />
                                                </Col>
                                            </Row>
                                        )}
                                    </>,
                                (a, b, order, dateField, rowA, rowB) => order === 'asc' ?
                                    rowA.patientId.localeCompare(rowB.patientId) : rowB.patientId.localeCompare(rowA.patientId),
                                true,
                                selectFilter({
                                    placeholder: "Select Read/Unread reports...",
                                    options: selectActionOptions,
                                    className: 'm-1',
                                    comparator: Comparator.LIKE
                                }),
                                (cell, row) => `${row.action ? 'RE' : 'UN'} ${cell}`
                            ),
                            getColumnObj('infoProvider', 'Info Provider'),
                            getColumnObj('risk', 'Risk', cell => {
                                return (
                                    <Col stretch>
                                        {cell.risk_covid &&
                                            <Row xs={12} style={{ padding: '2px' }}>
                                                <Col>
                                                    <BadgeRisks data={badgesColors.tableSymptomRisk['COVID']} />
                                                </Col>
                                            </Row>
                                        }
                                        {cell.risk_tb &&
                                            <Row xs={12} style={{ padding: '2px' }}>
                                                <Col>
                                                    <BadgeRisks data={badgesColors.tableSymptomRisk['TB']} />
                                                </Col>
                                            </Row>
                                        }
                                        {cell.risk_hepatitis &&
                                            <Row xs={12} style={{ padding: '2px' }}>
                                                <Col>
                                                    <BadgeRisks data={badgesColors.tableSymptomRisk['HEPATITIS']} />
                                                </Col>
                                            </Row>
                                        }
                                    </Col>
                                )
                            }),
                            getColumnObj('fever', 'Fever'),
                            getColumnObj('chills', 'Chills'),
                            getColumnObj('nightSweats', 'Night Sweats'),
                            getColumnObj('cough', 'Cough'),
                            getColumnObj('prolongedCough', 'Prolonged Cough'),
                            getColumnObj('fatigue', 'Fatigue'),
                            getColumnObj('headache', 'Headache'),
                            getColumnObj('headacheFrequency', 'Headache Frequency'),
                            getColumnObj('nauseaVomiting', 'Nausea/Vomiting'),
                            getColumnObj('weightLoss', 'Weight Loss'),
                            getColumnObj('dizziness', 'Dizziness'),
                            getColumnObj('breathShortness', 'Breath Shortness', undefined, undefined, false, undefined, undefined, false, 'align-middle text-nowrap col-xs-auto'),
                            getColumnObj('describeBreathing', 'Describe Breathing'),
                            getColumnObj('painfulThroat', 'Painful Throat'),
                            getColumnObj('lossSmell', 'Loss of Smell'),
                            getColumnObj('hoarseVoice', 'Hoarse Voice'),
                            getColumnObj('chestPain', 'Chest Pain'),
                            getColumnObj('abdominalPain', 'Abdominal Pain'),
                            getColumnObj('diarrhea', 'Diarrhea'),
                            getColumnObj('looseStools', 'Loose Stools'),
                            getColumnObj('colouredStools', 'Coloured Stools'),
                            getColumnObj('yellowingSkin', 'Yellowing Skin'),
                            getColumnObj('musclePain', 'Muscle Pain'),
                            getColumnObj('weltsSwelling', 'Welts Swelling'),
                            getColumnObj('feetBlisters', 'Feet Blisters'),
                            getColumnObj('confusion', 'Confusion'),
                            getColumnObj('eyeSoreness', 'Eye Soreness'),
                            getColumnObj('lossAppetite', 'Loss Appetite'),
                            getColumnObj('otherSymptoms', 'Other Symptoms'),
                            getColumnObj('options', 'Options')
                        ]}
                    >
                        {props =>
                            <div>
                                {!patientIdParam && (
                                    <SearchBar className="my-3"
                                        size="lg"
                                        placeholder="Search by Patient ID..." {...props.searchProps} />
                                )}

                                <PaginationProvider pagination={paginationFactory({
                                    custom: true,
                                    totalSize: Object.keys(symptomsData).length
                                })}>
                                    {
                                        ({
                                            paginationProps,
                                            paginationTableProps
                                        }) => (
                                            <div>
                                                <PaginationListStandalone
                                                    {...paginationProps}
                                                />

                                                <BootstrapTable
                                                    defaultSorted={defaultSorted}
                                                    striped
                                                    hover
                                                    bordered
                                                    wrapperClasses='table-responsive my-3'
                                                    filter={filterFactory()}
                                                    {...paginationTableProps}
                                                    {...props.baseProps}
                                                />
                                                <PaginationListStandalone
                                                    {...paginationProps}
                                                />
                                            </div>
                                        )
                                    }
                                </PaginationProvider>
                            </div>
                        }
                    </ToolkitProvider>
                </Container>
                :
                !patientNotFound && (
                    <div className='text-center'>
                        <Spinner animation='border' role='status' variant='primary'>
                            <span className='sr-only'>Loading...</span>
                        </Spinner>
                    </div>
                )
            }

            {showEditModal &&
                <ActionEditModal
                    action={selectedAction}
                    setAction={setSelectedAction}
                    dataId={showEditModal}
                    handleShow={setShowEditModal}
                    handleSend={() => handleSendEdit}
                    showModal={showEditModal}
                    comment={comment}
                    setComment={setComment}
                    logs={actionByReport}
                />
            }
            {showDeleteModal &&
                <DeleteModal
                    title='Delete Symptoms report'
                    description='Are you sure you want to delete this Symptoms report?'
                    handleSend={handleSendDelete}
                    dataId={showDeleteModal}
                    handleShow={handleShowDelete}
                    showModal={showDeleteModal}
                />
            }
            {showDownloadCSVModal &&
                <DownloadCSVModal
                    title='Download the content as CSV'
                    description='Select the method '
                    valuesCsvData={valuesCsvData}
                    labelsCsvData={labelsCsvData}
                    handleDownloadValue={() => { console.log('sending value') }}
                    handleShow={handleShowDownload}
                    showModal={showDownloadCSVModal}
                />
            }
        </>
    )
}

export default SymptomsTable