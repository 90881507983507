import userRoles from "./constants/userRoles";
import React from "react";

export const stringToDate = strDate => {
    const splittedDate = strDate.trim().split(' ');
    const [year, month, day] = splittedDate[0].split('-').map(s => parseInt(s));
    const [hour, minutes, seconds] = splittedDate[1].split(':').map(s => parseInt(s));

    return new Date(year, month - 1, day, hour, minutes, seconds);
}

/*export const isValidToken = (token, expirationDate) => {
    return token && expirationDate && stringToDate(expirationDate).getTime() > new Date().getTime();
}*/

export const isValidToken = token => !!token

// Used for the react-select component
export const valueToSelectObject = v => ({ value: v, label: v });
export const selectObjectToValue = obj => obj && obj.label;
export const valuesToSelectArray = v => v ? v.map(elem => ({ value: elem, label: elem })) : [];
export const selectArrayToValues = arr => arr.map(elem => elem.label);


// ROLES
export const isSuperAdmin = roleIds => roleIds.includes(userRoles.SUPER_ADMIN.id);
export const isAdmin = roleIds => roleIds.includes(userRoles.ADMIN.id);
export const isAdminOrSuperAdmin = roleIds => isAdmin(roleIds) || isSuperAdmin(roleIds);


// API
// Used for the API responses that are returned in an array instead of an object
export const dataToObject = (data, id = 'id') => {
    if (data !== null) {
        if (Array.isArray(data)) {
            let objectData = {};
            data.forEach(e => {
                objectData[e[id]] = e;
            });
            return objectData;
        }
        if (typeof data === 'object') return data;
    }

    throw new TypeError('The function was expecting an array or an object.');
};

// Used for get the values of a Response
export const getResponseData = response => response.data.data;
export const getResponseValues = response => response.data.data.values;
export const getResponseAccessToken = response => response.data.access_token;
export const getResponseClinicId = response => response.data.user.clinic_id;
export const getResponseRoleIds = response => response.data.user.roles.map(r => r.role_id);

// Used for React Bootstrap Table 2
export const getColumnObj = (
    /*dataField, text, formatter = undefined, sortFunc = undefined, searchable = false,
       sort = !!sortFunc, classes = 'align-middle', headerClasses = 'align-middle') => {
   return {dataField, text, formatter, sortFunc, sort, searchable, classes, headerClasses};*/
    dataField, text, formatter = undefined, sortFunc = undefined, searchable = false,
    filter = undefined, filterValue = undefined, sort = !!sortFunc, classes = 'align-middle', headerClasses = 'align-middle') => {
    return { dataField, text, formatter, sortFunc, sort, searchable, filter, filterValue, classes, headerClasses };
}


// Used for React Bootstrap Table 2
export const getColumnObjHidden = (
    /*dataField, text, formatter = undefined, sortFunc = undefined, searchable = false,
       sort = !!sortFunc, classes = 'align-middle', headerClasses = 'align-middle') => {
   return {dataField, text, formatter, sortFunc, sort, searchable, classes, headerClasses};*/
    dataField, text, formatter = undefined, searchable = false,
    hidden) => {
    return { dataField, text, formatter, searchable, hidden };
}

// Dates
// Return an array with [the number of days or hours between two dates, true if days / false if hours)]
export const getDaysOrHoursBetweensDates = (date1, date2) => {
    const time1 = date1.getTime();
    const time2 = date2.getTime();
    const timeDifference = Math.abs(time1 - time2);
    const millisecondsInADay = 1000 * 60 * 60 * 24;
    const daysDecimal = timeDifference / millisecondsInADay

    return daysDecimal < 1 ? [Math.floor(daysDecimal * 24), false] : [Math.floor(daysDecimal), true];
}

// Return the element to show in the notifications date message
export const getNotificationDateMessage = (date1, date2) => {
    const daysOrHours = getDaysOrHoursBetweensDates(date1, date2);
    return daysOrHours[1] ?
        <p className="text-secondary fs-6 my-0">{`${daysOrHours[0]} ${daysOrHours[0] === 1 ? 'day' : 'days'} ago`}</p> :
        <p className="text-primary fs-6 my-0">{`About ${daysOrHours[0]} ${daysOrHours[0] === 1 ? 'hour' : 'hours'} ago`}</p>
}

export const toLowerKeys = (obj) => {
    return Object.keys(obj).reduce((accumulator, key) => {
        accumulator[key.toLowerCase()] = obj[key];
        return accumulator;
    }, {});
}