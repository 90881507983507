import {GREEN, GREY, ORANGE, RED, WHITE, YELLOW} from "./colors";

export const defaultBadgesColors = {
    /*'0': '#5fca71',
    '1': '#46a6f8',
    '2': '#1c48c6',
    '3': '#5c28c6',
    '4': '#e29437',
    '5': '#bd2f5d'*/
    '0': GREY,
    '1': GREEN,
    '2': YELLOW,
    '3': ORANGE,
    '4': RED,
    '5': RED
}

export const reverseBadgesColors = {
    '5': GREEN,
    '4': ORANGE,
    '3': RED,
    '2': RED,
    '1': RED,
    '0': RED
}

//--- COVID ---//

export const tableSymptomDefault = {
    '0': 'No',
    '1': 'Yes'
}
export const tableSymptomFatigue = {
    '0': 'No',
    '1': 'Mild Fatigue',
    '2': 'Severe fatigue – I struggle to get out of bed'
}
export const tableSymptomHeadAches = {
    '0': 'All of the day',
    '1': 'Most of the day',
    '2': 'Some of the day'
}
export const tableSymptomBreath = {
    '0': 'Mild Symptoms – slight shortness of breath during ordinary activity',
    '1': 'Significant Symptoms – breathing is comfortable only at rest',
    '2': 'Severe Symptoms – breathing is difficult even at rest'
}
export const tableSymptomLooseStools = {
    '0': '1 - 2',
    '1': '3 - 4',
    '2': '5 +'
}
export const tableSymptomRisk = {
    'COVID': 'Covid',
    'TB': 'Tuberculosis',
    'HEPATITIS': 'Hepatitis'
}
export const tableSymptomRiskValue = {
    'true': '1',
    'false': '0',
}

export const risksToLabel = {
    'true': 'Yes',
    'false': 'No'
}

//--- IPOS ---//
export const tableIposInfoProvider = {
    '0': 'Patient',
    '1': 'Caregiver',
 
}
export const tableIposPain = {
    '0': 'No pain at all',
    '1': 'Slight pain',
    '2': 'Moderate pain',
    '3': 'Severe pain (interferes with activities of daily life)',
    '4': 'Very severe pain',
    '5': 'Overwhelming. The worst pain you can imagine'
}
export const tableIposSymptomsDefault = {
    '0': 'No at all',
    '1': 'Slight of',
    '2': 'Moderate',
    '3': 'Severe (interferes with activities of daily life)',
    '4': 'Very severe',
    '5': 'Overwhelming. The worst you can imagine'
}
export const tableIposConcern = {
    '0': 'Not at all worried',
    '1': 'Worried very occasionally',
    '2': 'Worried some of the time',
    '3': 'Worried a lot of the time',
    '4': 'Worried most of the time',
    '5': 'Worried all of the time'
}
export const tableIposFeelings = {
    '0': 'Not at all',
    '1': 'Only once',
    '2': 'Occasionally',
    '3': 'Fairly frequently',
    '4': 'Often',
    '5': "Yes, I've talked freely"
}
export const tableIposLife = {
    '0': 'Not at all',
    '1': 'Not very often',
    '2': 'Occasionally',
    '3': 'Some of the time',
    '4': 'Most of the time',
    '5': 'Yes, all the time'
}
export const tableIposPeace = {
    '0': 'Not at all',
    '1': 'Not very often',
    '2': 'Occasionally',
    '3': 'Some of the time',
    '4': 'Most of the time',
    '5': 'Yes, all the time'
}
export const tableIposAdvice = {
    '0': 'None',
    '1': 'Very little',
    '2': 'For a few things',
    '3': 'For several things',
    '4': 'For most things',
    '5': 'As much as wanted'
}

