import Moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toLowerKeys } from 'utils/helperFunctions';
import * as badgesColors from "utils/constants/badgesColors";
import { Button } from 'react-bootstrap';
import DownloadCSVModal from 'components/reusable/DownloadCSVModal';

const ExportCsv = () => {
  // TODO: Could generalize it into a hook for all exports at some point
  const [valuesCsvData, setValuesCsvData] = useState([]);
  const [isCsvDone, setIsCsvDone] = useState(false);
  const [showDownloadCSVModal, setShowDownloadCSVModal] = useState(null);

  const iposData = useSelector(({ iposData }) => iposData.data)

  useEffect(() => {
    // * Makes createCsvData run every time after iposData changes
    setIsCsvDone(false)
  }, [iposData])

  const handleShowDownload = mustShow => {
    setShowDownloadCSVModal(mustShow);
  }

  const createCsvData = () => {
    const tableHeaders = [["Report", "Submitted On", "Received Date", "Patient Id", "Info Provider", "Main Problems", "Pain", "Shortness of breath", "Weakness or lack of energy", "Nausea", "Vomiting", "Poor appetite", "Constipation", "Sore or dry mouth", "Drowsiness", "Poor mobility", "Other Symptoms 1 Text", "Other Symptoms 1 Value", "Other Symptoms 2 Text", "Other Symptoms 2 Value", "Other Symptoms 3 Text", "Other Symptoms 3 Value", "Patient Anxiety", "Family Anxiety", "Feeling depressed", "At Peace", "Share Feelings", "Information", "Practical Problems", "Completed by"]]

    // let newValuesCsvData = valuesCsvData;
    const csvData = Object.values(iposData).map(ipos => {
      ipos = toLowerKeys(ipos);
      return [
        ipos.id,
        Moment(ipos.created_at).format('DD-MM-YYYY'),
        Moment(ipos.received_at).format('DD-MM-YYYY'),
        ipos.patient_id,
        badgesColors.tableIposInfoProvider[ipos.pos_info_pov],
        (ipos.pos_mainproblems_patient !== undefined ? (ipos.pos_mainproblems_patient ? ipos.pos_mainproblems_patient : "-") : (ipos.pos_mainproblems_caregiver ? ipos.pos_mainproblems_caregiver : "-")),
        (ipos.pos_pain_patient !== undefined ? (ipos.pos_pain_patient >= 0 ? ipos.pos_pain_patient : "-") : (ipos.pos_pain_caregiver >= 0 ? ipos.pos_pain_caregiver : "-")),
        (ipos.pos_breathshortness_patient !== undefined ? (ipos.pos_breathshortness_patient >= 0 ? ipos.pos_breathshortness_patient : "-") : (ipos.pos_breathshortness_caregiver >= 0 ? ipos.pos_breathshortness_caregiver : "-")),
        (ipos.pos_weaklackenergy_patient !== undefined ? (ipos.pos_weaklackenergy_patient >= 0 ? ipos.pos_weaklackenergy_patient : "-") : (ipos.pos_weaklackenergy_caregiver >= 0 ? ipos.pos_weaklackenergy_caregiver : "-")),
        (ipos.pos_nausea_patient !== undefined ? (ipos.pos_nausea_patient >= 0 ? ipos.pos_nausea_patient : "-") : (ipos.pos_nausea_caregiver >= 0 ? ipos.pos_nausea_caregiver : "-")),
        (ipos.pos_vomiting_patient !== undefined ? (ipos.pos_vomiting_patient >= 0 ? ipos.pos_vomiting_patient : "-") : (ipos.pos_vomiting_caregiver >= 0 ? ipos.pos_vomiting_caregiver : "-")),
        (ipos.pos_poorappetite_patient !== undefined ? (ipos.pos_poorappetite_patient >= 0 ? ipos.pos_poorappetite_patient : "-") : (ipos.pos_poorappetite_caregiver >= 0 ? ipos.pos_poorappetite_caregiver : "-")),
        (ipos.pos_constipation_patient !== undefined ? (ipos.pos_constipation_patient >= 0 ? ipos.pos_constipation_patient : "-") : (ipos.pos_constipation_caregiver >= 0 ? ipos.pos_constipation_caregiver : "-")),
        (ipos.pos_sore_patient !== undefined ? (ipos.pos_sore_patient >= 0 ? ipos.pos_sore_patient : "-") : (ipos.pos_sore_caregiver >= 0 ? ipos.pos_sore_caregiver : "-")),
        (ipos.pos_drowsiness_patient !== undefined ? (ipos.pos_drowsiness_patient >= 0 ? ipos.pos_drowsiness_patient : "-") : (ipos.pos_drowsiness_caregiver >= 0 ? ipos.pos_drowsiness_caregiver : "-")),
        (ipos.pos_poormobility_patient !== undefined ? (ipos.pos_poormobility_patient >= 0 ? ipos.pos_poormobility_patient : "-") : (ipos.pos_poormobility_caregiver >= 0 ? ipos.pos_poormobility_caregiver : "-")),

        (ipos.pos_othersym_text_1_patient !== undefined ? (ipos.pos_othersym_text_1_patient ? ipos.pos_othersym_text_1_patient : "-") : (ipos.pos_othersym_text_1_caregiver ? ipos.pos_othersym_text_1_caregiver : "-")),
        (ipos.pos_othersym_value_1_patient !== undefined ? (ipos.pos_othersym_value_1_patient >= 0 ? ipos.pos_othersym_value_1_patient : "-") : (ipos.pos_othersym_value_1_caregiver >= 0 ? ipos.pos_othersym_value_1_caregiver : "-")),
        (ipos.pos_othersym_text_2_patient !== undefined ? (ipos.pos_othersym_text_2_patient ? ipos.pos_othersym_text_2_patient : "-") : (ipos.pos_othersym_text_2_caregiver ? ipos.pos_othersym_text_2_caregiver : "-")),
        (ipos.pos_othersym_value_2_patient !== undefined ? (ipos.pos_othersym_value_2_patient >= 0 ? ipos.pos_othersym_value_2_patient : "-") : (ipos.pos_othersym_value_2_caregiver >= 0 ? ipos.pos_othersym_value_2_caregiver : "-")),
        (ipos.pos_othersym_text_3_patient !== undefined ? (ipos.pos_othersym_text_3_patient ? ipos.pos_othersym_text_3_patient : "-") : (ipos.pos_othersym_text_3_caregiver ? ipos.pos_othersym_text_3_caregiver : "-")),
        (ipos.pos_othersym_value_3_patient !== undefined ? (ipos.pos_othersym_value_3_patient >= 0 ? ipos.pos_othersym_value_3_patient : "-") : (ipos.pos_othersym_value_3_caregiver >= 0 ? ipos.pos_othersym_value_3_caregiver : "-")),

        (ipos.pos_concern_patient !== undefined ? (ipos.pos_concern_patient >= 0 ? ipos.pos_concern_patient : "-") : (ipos.pos_concern_caregiver >= 0 ? ipos.pos_concern_caregiver : "-")),
        (ipos.pos_feelings_patient !== undefined ? (ipos.pos_feelings_patient >= 0 ? ipos.pos_feelings_patient : "-") : (ipos.pos_feelings_caregiver >= 0 ? ipos.pos_feelings_caregiver : "-")),
        (ipos.pos_lifeworthwhile_patient !== undefined ? (ipos.pos_lifeworthwhile_patient >= 0 ? ipos.pos_lifeworthwhile_patient : "-") : (ipos.pos_lifeworthwhile_caregiver >= 0 ? ipos.pos_lifeworthwhile_caregiver : "-")),
        (ipos.pos_peacefeeling_patient !== undefined ? (ipos.pos_peacefeeling_patient >= 0 ? ipos.pos_peacefeeling_patient : "-") : (ipos.pos_peacefeeling_caregiver >= 0 ? ipos.pos_peacefeeling_caregiver : "-")),
        (ipos.pos_help_patient !== undefined ? (ipos.pos_help_patient >= 0 ? ipos.pos_help_patient : "-") : (ipos.pos_help_caregiver >= 0 ? ipos.pos_help_caregiver : "-")),
        (ipos.pos_info_patient !== undefined ? (ipos.pos_info_patient >= 0 ? ipos.pos_info_patient : "-") : (ipos.pos_info_caregiver >= 0 ? ipos.pos_info_caregiver : "-")),
        (ipos.pos_problems_patient !== undefined ? (ipos.pos_problems_patient >= 0 ? ipos.pos_problems_patient : "-") : (ipos.pos_problems_caregiver >= 0 ? ipos.pos_problems_caregiver : "-")),

        (ipos.pos_completion_patient || ipos.pos_completion_caregiver) === "0" ? "On my own" :
          (ipos.pos_completion_patient || ipos.pos_completion_caregiver) === "1" ? "Friend or relative" :
            (ipos.pos_completion_patient || ipos.pos_completion_caregiver) === "2" ? "Member of staff" : "-"
      ]
    })
    setValuesCsvData([...tableHeaders, ...csvData]);
    setIsCsvDone(true);
  }

  useEffect(() => {
    if (!isCsvDone && iposData) {
      createCsvData()
    }
  })

  return (
    <>
      <Button className='mr-2' onClick={() => {
        handleShowDownload(true)
      }}>Export as CSV</Button>
      {
        showDownloadCSVModal &&
        <DownloadCSVModal
          title='Download the content as CSV'
          description='Export the values as CSV '
          valuesCsvData={valuesCsvData}
          handleDownloadValue={() => {
            console.log('sending value')
          }}
          handleShow={handleShowDownload}
          showModal={showDownloadCSVModal}
        />
      }
    </>
  )
}

export default ExportCsv