import React from 'react'
import {Badge, Button} from 'react-bootstrap'
import PropTypes from 'prop-types'

const ActionLabel = ({title, handleClick}) =>
    <Badge
        as={Button}
        pill variant="primary"
           className="align-items-center justify-content-center m-1"
           style={{
               fontSize: '16px',
               color: '#fff',
           }}
           onClick={handleClick}
    >
        {title}
    </Badge>;
/*return (
    <Badge style={{'fontSize': '14px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff', marginRight: '0.5em'}} variant='primary'>{title}</Badge>
)*/


ActionLabel.prototype = {
    title: PropTypes.string.isRequired
}

export default ActionLabel