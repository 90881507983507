import axios from 'axios';
import { FCM_URL, NOTIFICATIONS_LOG, NOTIFICATIONS_READ_URL, NOTIFICATIONS_URL, ALL_LOGS_BY_TYPE, ALL_LOGS_OF_PATIENT, NOTIFICATION_URL } from "../constants/myApiUrls";
import { MyAxiosInstance } from "../index";
import { REMINDER_TITLE } from "../constants/reminders";

const SERVER_KEY = "AAAAEEC30CM:APA91bG53pzLYf6NQxq2WpmDKXm1FzFS7EHAfZtpsjyoKJBRBowsJ87JsB-1s8sFeeFUFEWzLPgbYuY1BPZ0uuRfUsYqKoSlB2wZ9mOsiUgdbFMdCx2K6y2EhvbXJ4G97h03gB7l0SFJ";
const fcmHeaders = { Authorization: `key=${SERVER_KEY}` };


/**
 * Perform a GET request to the Patients URL to retrieve the patients data.
 */
const getNotifications = () => MyAxiosInstance.get(NOTIFICATIONS_URL);

/**
 * Perform a POST request to the Logs URL to send a log
 */
const postNotificationLog = (type, message, patient_id) => MyAxiosInstance.post(NOTIFICATIONS_LOG(type, message, patient_id))

const postNotification = data => MyAxiosInstance.post(NOTIFICATION_URL, data);

const getAllLogsByType = (type, clinicId) => MyAxiosInstance.get(ALL_LOGS_BY_TYPE(type, clinicId))
const getLogsByTypeAndPatient = (type, patientId) => MyAxiosInstance.get(ALL_LOGS_OF_PATIENT(type, patientId))

/**
 * Perform a POST request to the Notifications URL to mark some notifications as seen
 */
const markNotificationsAsSeen = data => MyAxiosInstance.put(NOTIFICATIONS_READ_URL, data);

/**
 * Perform a GET request to the Patient URL to retrieve the desired patient data.
 */
const sendFcm = (fcmToken, body) => {
    const obj = {
        click_action: "FLUTTER_NOTIFICATION_CLICK",
        android_channel_id: 'CHANNEL_MPALLIATIVE_CARE_APP',
        sound: 'default',
        body,
        title: REMINDER_TITLE,
    }
    const data = {
        to: fcmToken,
        notification: obj,
        android: {
            sound: 'default'
        },
        data: obj
    }
    return axios.post(FCM_URL, data, { headers: fcmHeaders });
}

export default {
    getNotifications,
    markNotificationsAsSeen,
    postNotificationLog,
    sendFcm,
    getAllLogsByType,
    getLogsByTypeAndPatient,
    postNotification
};