// Base
//const _DOMAIN = 'https://apca-mpalliative.universaldoctor.com'; // Domain of the server
const _DOMAIN = 'https://backend.ipos.universaldoctor.com\n'; // Domain of the server
const _API_PATH = 'api'; // Path to the API
export const BASE_PATH = `${_DOMAIN}/${_API_PATH}`; // Base URL of the backend

// Authentication
const _LOGIN_PATH = 'login';
const _USER_PATH = 'user';
export const LOGIN_URL = `/${_LOGIN_PATH}`;
export const USER_URL = `/${_USER_PATH}`;


// Patients
const _PATIENT_PATH = 'patient'
const _PATIENTS_PATH = 'patients'
export const PATIENT_URL = `/${_PATIENT_PATH}`;
export const PATIENTS_URL = (clinic) => `/${_PATIENTS_PATH}?clinic_id=${clinic}`;
export const PATIENT_UPDATE_URL = id => `/${_PATIENT_PATH}/${id}`;


// Reports
const _REPORT_PATH = 'report'
export const REPORT_URL = `/${_REPORT_PATH}`;
const _REPORTS_PATH = 'reports'
export const REPORTS_URL = `/${_REPORTS_PATH}`;
export const REPORT_KEY_URL = (key, clinic) => `/reports-key/?keyForm=${key}&clinic_id=${clinic}`;
export const REPORTS_SUMMARY = (clinic) => `${REPORTS_URL}/summary?clinic_id=${clinic}`;
export const REPORT_UPDATE = id => `/report/${id}`;
export const REPORT_DELETE = id => `/report/${id}`;
export const PATIENT_REPORTS_URL = (patientId, formKey) => `/reports-patient/?patient_id=${patientId}&keyForm=${formKey}`;
export const EXPORT_PDF = (reportId) => `/export-pdf/${reportId}`;

// Notifications
const _NOTIFICATION_PATH = 'notification';
const _NOTIFICATIONS_PATH = 'notifications';
const _LOGS_PATH = 'log'
export const NOTIFICATION_URL = `/${_NOTIFICATION_PATH}`
export const NOTIFICATIONS_URL = `/${_NOTIFICATIONS_PATH}`
export const NOTIFICATIONS_READ_URL = `${NOTIFICATION_URL}/read`
export const NOTIFICATIONS_LOG = (type, message, patient_id) => `/${_LOGS_PATH}?type=${type}&message=${message}&patient_id=${patient_id}`
export const ALL_LOGS_BY_TYPE = (type, clinicId) => `/logs/?type=${type}&clinic_id=${clinicId}`;
export const ALL_ACTION_LOGS_OF_REPORT = (type, reportId) => `/logs/?type=${type}&report_id=${reportId}`;
export const ALL_LOGS_OF_PATIENT = (type, patientId) => `/logs/?type=${type}&patient_id=${patientId}`;
export const ALL_ACTION_LOGS = (type, clinic_id) => `/logs/?type=${type}&clinic_id=${clinic_id}`;
export const FCM_URL = "https://fcm.googleapis.com/fcm/send";