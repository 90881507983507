/**
 * Class that controls the storage of the browser
 */
export default class MyStorageManager {
    _USERNAME = 'mPalliative-username';
    _PWD = 'mPalliative-pwd';
    _TOKEN = 'mPalliative-token';
    _EXPIRATION_DATE = 'mPalliative-token-expiration';
    _CLINIC_ID = 'mPalliative-clinic-id';
    _NOTIFICATIONS_READ = 'notifications-read';
    _localStorage = window.localStorage;

    // Get and store the username
    getUsername = () => this._localStorage.getItem(this._USERNAME,) || "";
    storeUsername = username => this._localStorage.setItem(this._USERNAME, username);

    // Get and store the password
    getPwd = () => this._localStorage.getItem(this._PWD) || "";
    storePwd = pwd => this._localStorage.setItem(this._PWD, pwd);

    // Get and store the token
    getToken = () => this._localStorage.getItem(this._TOKEN) || "";
    storeToken = token => this._localStorage.setItem(this._TOKEN, token);

    // Get and store the username
    getClinicId = () => this._localStorage.getItem(this._CLINIC_ID,) || "";
    storeClinicId = clinicId => this._localStorage.setItem(this._CLINIC_ID, clinicId);

    // Get and store the expiration date of the token
    getExpirationDate = () => this._localStorage.getItem(this._EXPIRATION_DATE) || "";
    storeExpirationDate = date => this._localStorage.setItem(this._EXPIRATION_DATE, date);

    // Store both the username and the password
    storeCredentials = (username, pwd) => {
        this.storeUsername(username);
        this.storePwd(pwd);
    }

    // Store the username, the password and the token
    storeCredentialsWithToken = (username, pwd, token) => {
        this.storeUsername(username);
        this.storePwd(pwd);
        this.storeToken(token)
    }

    // Store both the token and its expiration date
    storeTokenWithExpirationDate = (token, date) => {
        this.storeToken(token);
        this.storeExpirationDate(date);
    }

    // Clear local storage
    clear = () => this._localStorage.clear();

    // Clear local storage except the username and the password
    clearExceptCredentials = () => {
        this._localStorage.removeItem(this._TOKEN);
        this._localStorage.removeItem(this._EXPIRATION_DATE);
    }

    // Get notifications read
    getNotifications = () => {
        const notifications = this._localStorage.getItem(this._NOTIFICATIONS_READ);
        return notifications ? JSON.parse(notifications) : [];
    }

    // Store the notifications that have been read
    markNotificationsAsRead = notifications => {
        this._localStorage.setItem(this._NOTIFICATIONS_READ, JSON.stringify(notifications))
    }

    // Clear the notifications
    clearNotifications = () => {
        this._localStorage.removeItem(this._NOTIFICATIONS_READ)
    }
}