import { getResponseData } from "utils/helperFunctions"
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Button } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import ReportsService from "utils/services/ReportsService";
import actionsNames from 'utils/constants/actionsNames';
import { MyStorageManager } from "utils";

const ExportLogsCsv = forwardRef((props, ref) => {
  const myStorageManager = new MyStorageManager
  const [logsCsvData, setLogsCsvData] = useState([]);
  const [isLogCsvDone, setIsLogCsvDone] = useState(false);

  const iposData = useSelector(({ iposData }) => iposData.data)

  useEffect(() => {
    // * Makes createCsvData run every time after iposData changes
    setIsLogCsvDone(false)
  }, [iposData])

  useImperativeHandle(ref, () => ({
    createLogCsvData
  }));

  async function createLogCsvData() {
    const logCsvDataHeaders = [["ID", "Created At", "User", "Patient ID", "Type", "Report ID", "Action", "Comment"]]

    setIsLogCsvDone(true);

    try {
      const resp = await ReportsService.getAllActionLogs('ipos_action', myStorageManager.getClinicId());
      const response = getResponseData(resp)
      const results = Object.keys(response.values).map((key) => response.values[key]);

      const logCsvDataValues = results.map(result => {
        if (result.log !== ("Hello. This is a reminder that we would like you to complete the iPOS form in the mPalliative app. Please can you access the app and complete the form. Please contact Peace Hospice if you are experiencing difficulties with the app") &&
          result.log !== "Hello. This is a reminder that we would like you to complete the iPOS form in the mPalliative app. Please can you access the app and complete the form. Please contact Peace Hospice if you are experiencing difficulties with the app") {
          return ([
            result.id,
            result.created_at,
            result.user,
            result.patient_id,
            result.action_type,
            result.report_id || "-",
            actionsNames[result.new_action]?.label || "-",
            result.new_comment || "-"
          ])
        }
      }).filter(dataPoint => dataPoint)

      setLogsCsvData([...logCsvDataHeaders, ...logCsvDataValues]);

    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (!isLogCsvDone && iposData) {
      createLogCsvData()
    }
  }, [iposData])

  return (
    <CSVLink data={logsCsvData} filename='logs_table.csv'>
      <Button>Export Logs</Button>
    </CSVLink>
  )
})

export default ExportLogsCsv