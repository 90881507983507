import React, {useState, useEffect} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import {Container, Dropdown, Spinner, Row, Col, Badge, Alert, Button} from 'react-bootstrap';
import { getColumnObj } from '../../utils/helperFunctions';
import CustomNavbar from '../Dashboard/Navbar/CustomNavbar';
import {useLocation} from 'react-router-dom';
import DownloadCSVModal from '../reusable/DownloadCSVModal'
import DownloadPatientsCSVModal from '../reusable/DownloadPatientsCSVModal';

const SubmissionCalendarTable = () => {
    const { state } = useLocation();
    const [calendarData, setCalendarData] = useState(null);
    const [valuesCsvData, setValuesCsvData] = useState([["Form Number", "User ID", "Due Date", "State", "Date Answered"]]);
    const [isCsvDone, setIsCsvDone] = useState(false);
    const [showDownloadCSVModal, setShowDownloadCSVModal] = useState(null);

    useEffect(() => {
        try{
            createData()
        }catch(error){
            console.log(error);
        }
    }, []);

    const createData = () => {
        var finalData = []
        // state.calendarData.map(data => {
        for(var l = 0; l < state.calendarData.length; l++){
            if(state.calendarData[l].reports.length > 0){
                for(var i = 0; i < state.calendarData[l].reports.length; i++){
                    finalData = finalData.concat(
                        {
                            'id': state.calendarData[l].reports[i].id,
                            'date': state.calendarData[l].date,
                            'state': state.calendarData[l].state,
                            'date_answered': state.calendarData[l].reports[i].date_answered,
                            'form_number': state.calendarData[l].reports[i].form_number
                        }
                    )
                }
            }else{
                finalData = finalData.concat(
                    {
                        'id': l,
                        'date': state.calendarData[l].date,
                        'state': state.calendarData[l].state,
                        'date_answered': '',
                        'form_number': l+1
                    }
                )
            }
        }
        setCalendarData(finalData)
    }

    const createCsvData = () => {
        var newValuesCsvData = valuesCsvData;

        Object.values(calendarData).map(data => {
            newValuesCsvData = [...newValuesCsvData, [
                data.form_number != null ? data.form_number : '',
                data.patientId != null ? data.patientId : '',
                data.date != null ? data.date : '',
                data.state != null ? data.state : '',
                data.date_answered != null ? data.date_answered : ''
            ]]
        })
        setValuesCsvData(newValuesCsvData);
        setIsCsvDone(true);
    }

    const handleShowDownload = mustShow => {
        setShowDownloadCSVModal(mustShow);
    }

    if(!isCsvDone && calendarData){
        createCsvData()
    }

    return(
        <>
            <CustomNavbar/>
            {calendarData &&
            <Container fluid>
                <Button onClick={() => {handleShowDownload(true)}}>Export as CSV</Button>
                <ToolkitProvider
                keyField='key'
                bootstrap4
                data={calendarData.map(data => {
                    return{
                        key: data.id ? data.id : data.state,
                        formNumber: data.form_number,
                        patientId: state.patientId,
                        date: data.date ? data.date : '-',
                        state: data.state ? data.state : '-',
                        dateAnswered: data.date_answered ? data.date_answered : '-'
                    }
                })}
                columns={[
                    getColumnObj('formNumber','Form Number'),
                    getColumnObj('patientId','User ID'),
                    getColumnObj('date','Due date'),
                    getColumnObj('state','State'),
                    getColumnObj('dateAnswered', 'Date Answered')
                ]}
                >
                    {props => 
                        <div>
                            <BootstrapTable
                                // defaultSorted={defaultSorted}
                                striped
                                hover
                                bordered
                                wrapperClasses='table-responsive my-3'
                                {...props.baseProps}
                            />
                        </div>
                    }
                </ToolkitProvider>
            </Container>
            }
            {showDownloadCSVModal &&
                <DownloadPatientsCSVModal
                    title='Download the content as CSV'
                    description='Select the method '
                    csvData={valuesCsvData}
                    handleDownloadValue={()=>{console.log('sending Do you want to download the content as CSV?')}}
                    handleShow={handleShowDownload}
                    showModal={showDownloadCSVModal}
                />
            }
        </>
    )
}

export default SubmissionCalendarTable