import React from 'react';
import {Card, Col, Container, Row} from "react-bootstrap";
import {BasicSpinner, CustomCard} from "../reusable";
import PropTypes from 'prop-types';

/**
 * Component to show the missed reports of IPOS and COVID
 *
 * @component
 *
 * @param {string} formTitle: title of the form
 * @param {string} missedReports: number of missed reports
 *
 * @example
 * <MissedReportsCard formTitle="Missed IPOS reports" missedReports="9"/>
 */
const MissedReportsCard = ({formTitle, missedReports,}) => {
    const body = (
        <>
            <Card.Body>
                <Container fluid className="h-100">
                    <Row className="text-left">
                        <Col>
                            <Card.Title as="h4">{formTitle}</Card.Title>
                        </Col>
                    </Row>

                    <Row className="text-right">
                        <Col>
                            {missedReports ?
                                <Card.Text className="display-2 text-info font-weight-bold">{missedReports}</Card.Text> :
                                <BasicSpinner/>
                            }
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </>
    );

    return <CustomCard> {body} </CustomCard>

}

MissedReportsCard.propTypes = {
    formTitle: PropTypes.string.isRequired,
    missedReports: PropTypes.string.isRequired
}

export default MissedReportsCard;
