import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row, Dropdown } from "react-bootstrap";
import PropTypes from 'prop-types';
import actionsNames from '../../utils/constants/actionsNames';
import '../SymptomsTable/SymptomsTable.css'
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import { updateIposDataAction } from 'reducers/iposDataReducer';
import ReportsService from 'utils/services/ReportsService';
import { getResponseData } from 'utils/helperFunctions';
/**
 * Component to show a modal with different options for sending reminders or a custom one
 *
 * @component
 *
 * @param {string} rowId
//  * @param {function} handleShow: tell the parent component if the modal needs to be shown
 * @param {function} handleSendingPerformed: tell the parent component the result of sending the reminder
 * @param showModal: if true, the modal is shown
 *
 * @example
 * const handleShow = show => setShowModal(show);
 * const handleSendingPerformed = satisfactory => setAlert(satisfactory);
 * const showModal = true;
 * const handleCreate = () => setShowModal(false);
 * <SendReminderModal patientId={patientId} handleShow={handleShow}
 * handleSendingPerformed={handleSendingPerformed} showModal={true} custom={true}/>
 */
// ! TODO NEXT: Set default dropdown option to current value if it has one
const ActionEditModal = ({ rowId, handleShow, showModal = false, handleSendingPerformed, updateLogExport }) => {
  const [selectedAction, setSelectedAction] = useState(null);
  const [comment, setComment] = useState('');
  const [rowActionLogsHistory, setRowActionLogsHistory] = useState(null);
  const [rowCsvData, setRowCsvData] = useState([["ID", "Created At", "User", "Patient ID", "Action", "Comment"]]);
  const [isRowCsvDone, setIsRowCsvDone] = useState(false);
  const [errorMessage, setErrorMessage] = useState("")

  const patientData = useSelector(({ iposData }) => Object.values(iposData.data).find((patient) => patient.id === rowId))

  const dispatch = useDispatch()

  const handleClose = () => {
    handleShow(null)
  }

  useEffect(() => {
    setComment(patientData.comment)
    setSelectedAction(patientData.action)
  }, [patientData.comment, patientData.action])

  useEffect(() => {
    const handlePatientActionLogsReport = async () => {
      setRowActionLogsHistory(null);
      try {
        const resp = await ReportsService.getActionLogsOfReport('ipos_action', rowId);
        const response = getResponseData(resp)
        if (response) {
          const result = Object.keys(response.values).map((key) => response.values[key]);
          setRowActionLogsHistory(result);
        }
      } catch (err) {
        console.error(err)
      }
    }
    handlePatientActionLogsReport()
  }, [rowId])

  const createRowCsvData = () => {
    if (rowActionLogsHistory.length) {
      let newCsvData = rowCsvData
      Object.values(rowActionLogsHistory).map(log => {
        newCsvData = [...newCsvData, [
          log.id,
          log.created_at,
          log.user,
          log.patient_id,
          actionsNames[log.new_action.toString()].name,
          log.new_comment
        ]]
      })
      setRowCsvData(newCsvData);
      setIsRowCsvDone(true);
    }
  }

  useEffect(() => {
    if (!isRowCsvDone && rowActionLogsHistory?.length) {
      // ! Memory leak generated by createRowCsvData if user change pages quickly after updating the row and the function is still running
      createRowCsvData()
      // ! Can be solved by unmounting this hook using the code below, but the row to be exported will contain no data
      // See https://stackoverflow.com/questions/54954385/react-useeffect-causing-cant-perform-a-react-state-update-on-an-unmounted-comp
      // return () => {
      //   setRowCsvData([["ID", "Created At", "User", "Patient ID", "Action", "Comment"]])
      // }
    }
  })

  const handleEdit = async (e) => {
    e.preventDefault()
    if (!selectedAction) {
      setErrorMessage("Please select an action type to continue")
      return
    }
    if (!comment) {
      setErrorMessage("Please make a comment to continue")
      return
    }
    const newIposData = { ...patientData, action: selectedAction, comment: comment, log_type: 'ipos_action' }
    try {
      dispatch(updateIposDataAction(newIposData, handleSendingPerformed, updateLogExport))
      setIsRowCsvDone(false);
    } catch (error) {
      handleSendingPerformed(false, true)
      console.error(error)
    }
  }

  function handleSelectAction(e) {
    setErrorMessage("")
    setSelectedAction(e)
  }

  function handleComment(e) {
    setErrorMessage("")
    setComment(e.target.value)
  }

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Actions</Modal.Title>
      </Modal.Header>

      <Form onSubmit={handleEdit}>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <Form.Group controlId="formCustomMessage">
                  <Dropdown onSelect={handleSelectAction}>
                    <Dropdown.Toggle variant="primary">
                      {actionsNames[selectedAction]?.label || 'Select One'}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item eventKey='Nothing'>{actionsNames['0'].name}</Dropdown.Item>
                      <Dropdown.Item eventKey='Discuss'>{actionsNames['1'].name}</Dropdown.Item>
                      <Dropdown.Item eventKey='Contact'>{actionsNames['2'].name}</Dropdown.Item>
                      <Dropdown.Item eventKey='Other'>{actionsNames['3'].name}</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  Comments
                  <Form.Control
                    as="textarea" rows={6} value={comment}
                    onChange={handleComment}
                    placeholder="No comments yet..."
                  />
                </Form.Group>
                {rowActionLogsHistory != null ?
                  <div className='separator mt-5' />
                  : <></>}
                {rowActionLogsHistory != null ?
                  <Container style={{ display: 'flex', justifyContent: 'center' }}>
                    <h5>
                      Last Logs
                    </h5>
                  </Container>
                  : <></>
                }
                {rowActionLogsHistory != null ? <Container style={{ flex: 1, height: '100px', border: '1px solid rgba(0, 0, 0, 0.4)', overflowY: 'scroll' }}>
                  <ul>
                    {rowActionLogsHistory.map((log) => (
                      <li key={log.id}>
                        {log.log} / {log.created_at}
                      </li>
                    ))}
                  </ul>
                </Container> : <></>}
              </Col>
            </Row>
            {errorMessage &&
              <Row>
                <div style={{ marginLeft: "12px", color: "red" }}>
                  {errorMessage}
                </div>
              </Row>
            }
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <CSVLink data={rowCsvData} filename='logs_actions.csv'>
            <Button onClick={() => { handleClose() }}>
              Export
            </Button>
          </CSVLink>
          <Button variant="primary" type="submit" >
            Send
          </Button>
        </Modal.Footer>

      </Form>
    </Modal>
  )
}

ActionEditModal.propTypes = {
  rowId: PropTypes.string.isRequired,
  handleShow: PropTypes.func.isRequired,
  handleSendingPerformed: PropTypes.func.isRequired,
}

export default ActionEditModal;