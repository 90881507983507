import {MyAxiosInstance} from "../../utils";
import {PATIENT_UPDATE_URL, PATIENT_URL, PATIENTS_URL} from "../constants/myApiUrls";

const qs = require('qs');

/**
 * Perform a GET request to the Patient URL to retrieve the desired patient data.
 */
const getPatient = params => MyAxiosInstance.get(PATIENT_URL, params);

/**
 * Perform a GET request to the Patients URL to retrieve the patients data.
 */
const getPatients = (clinic_id) => MyAxiosInstance.get(PATIENTS_URL(clinic_id));

/**
 * Perform a PUT request to the Patients URL to update the patient data
 */
const updatePatient = (id, data) => MyAxiosInstance.put(PATIENT_UPDATE_URL(id), data);

export default {
    getPatient,
    getPatients,
    updatePatient
};