import React, { useState, useEffect } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { useAuth } from "../../context/auth";
import { MyStorageManager } from "../../utils";
import LoginService from "../../utils/services/LoginService";
import Logo from "../reusable/Logo/Logo";
import { getResponseAccessToken, getResponseClinicId, getResponseRoleIds, isAdminOrSuperAdmin } from "../../utils/helperFunctions";
import { useMatomo } from "@jonkoops/matomo-tracker-react"

/**
 * Component to log in the system.
 *
 * @component
 *
 * @example
 * <Login />
 */
export default function Login() {
    // Storage manager
    const myStorageManager = new MyStorageManager();

    // Data from Auth Context Provider
    const { isLoggedIn, login } = useAuth();

    // Fields of the form
    const [username, setUsername] = useState(myStorageManager.getUsername());
    const [password, setPassword] = useState(myStorageManager.getPwd());
    const [rememberMe, setRememberMe] = useState(0);

    // Validation alert
    const [showValidationAlert, setShowValidationAlert] = useState(false);
    const validationMessage = "Incorrect username or password";
    const LogInAlert = <Alert className="m" variant='danger'>{validationMessage}</Alert>;
    const { trackPageView } = useMatomo()

    useEffect(() => {
        trackPageView()
    }, [])
    // const referer = props.location.state.referer || '/dashboard';

    /**
     * Send the form data (if valid) to the server when the submit button is clicked.
     */
    const handleSubmit = e => {
        e.preventDefault();
        if (username === "" || password === "")
            setShowValidationAlert(true);

        else {
            // Query params to send via POST request
            const params = {
                username,
                password,
                'remember-me': rememberMe
            };

            // Using the login service
            LoginService.login(params)
                .then(res => {
                    const token = getResponseAccessToken(res);
                    const roles = getResponseRoleIds(res);
                    const clinicId = getResponseClinicId(res);

                    if (isAdminOrSuperAdmin(roles)) {
                        myStorageManager.storeToken(token);
                        myStorageManager.storeClinicId(clinicId);
                        if (rememberMe)
                            myStorageManager.storeCredentials(username, password);

                        setShowValidationAlert(false);
                        login(token, roles);
                    } else {
                        setShowValidationAlert(true);
                    }

                }).catch(err => {
                    setShowValidationAlert(true);
                })
        }
    }

    return (
        isLoggedIn ?
            <Redirect to="/patients" /> :
            <Container className="d-flex align-items-center justify-content-center min-vh-100">
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col className="text-center">
                            <Logo />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3 className="text-center">Sign in to IPOS</h3>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <Form.Group controlId="formUsername">
                                <Form.Label>Username</Form.Label>
                                <Form.Control
                                    autoFocus
                                    type="username"
                                    placeholder="Enter username"
                                    value={username}
                                    onChange={e => setUsername(e.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group controlId="formPwd">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Enter password"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Check
                                type="checkbox"
                                label="Remember me"
                                checked={rememberMe}
                                onChange={e => setRememberMe(e.target.checked ? 1 : 0)} />
                        </Col>
                    </Row>

                    <Row className="text-center mt-4">
                        <Col>
                            <Button variant="primary" type="submit" size="lg">
                                Sign in
                            </Button>
                        </Col>
                    </Row>

                    {showValidationAlert && (
                        <Row className="mt-4">
                            <Col>
                                {LogInAlert}
                            </Col>
                        </Row>
                    )}
                </Form>
            </Container>
    )
}