import React from "react";

const BadgeColor = ({data}) => {
    return (
        /*<div className={`badge custom-badge badge-primary custom-badge-primary ${data === "Yes" ?
            "badge-outlined custom-badge-outlined" : ""}`}>
            {data}
        </div>*/
        <div className={`badge custom-badge-risks`}>
            {data}
        </div>
    )
}

export default BadgeColor;