import React from 'react'
import {Button, Col, Container, Form, Modal, Row} from 'react-bootstrap'
import PropTypes from 'prop-types'

const ReminderErrorModal = ({handleShow, showModal = false, title, description}) => {

    const handleClose = () => {
        handleShow(null)
    }

    return (
        <Modal
            size='lg'
            show={showModal}
            onHide={handleClose}
            backdrop='static'
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Form>
                <Modal.Body>
                    <Container>
                        {description}
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

ReminderErrorModal.propTypes = {
    handleShow: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
}

export default ReminderErrorModal;