import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Dropdown, Row } from 'react-bootstrap';
import {
    BiMessageAltX,
    BiMessageCheck,
    BsAlarm,
    BsPencil,
    BsReply,
    BsThreeDotsVertical,
    ImUserCheck
} from "react-icons/all";
import { Link, Redirect } from "react-router-dom";
import CustomNavbar from "../Dashboard/Navbar/CustomNavbar";
import { useAuth } from "../../context/auth";
import './PatientsTable.css';
import PatientsService from "../../utils/services/PatientsService";

import SendReminderModal from "./SendReminderModal";
import { getColumnObj, getColumnObjHidden, getResponseData } from "../../utils/helperFunctions";
import MissedReportsCard from "./MissedReportsCard";
import ReportsService from "../../utils/services/ReportsService";
import { BasicSpinner } from "../reusable";
import { getStyleByColorName } from "../../utils/constants/colors";

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationListStandalone, PaginationProvider } from 'react-bootstrap-table2-paginator';
import ReminderErrorModal from './ReminderErrorModal';
import DownloadPatientsCSVModal from '../reusable/DownloadPatientsCSVModal';
import NotificationsService from "../../utils/services/NotificationsService";
import { MyStorageManager } from '../../utils';
import { useMatomo } from "@jonkoops/matomo-tracker-react"
import { CSVLink } from 'react-csv';

const PatientsTable = ({ location }) => {
    const myStorageManager = new MyStorageManager();
    const { isLoggedIn, logout } = useAuth();
    const [patientIdToEdit, setPatientIdToEdit] = useState(null);
    const [logsByPatient, setLogsByPatient] = useState(null);
    const [patientsData, setPatientsData] = useState(null);
    const [csvData, setCsvData] = useState([]);
    const [logsCsvData, setLogsCsvData] = useState([]);
    const [isCsvDone, setIsCsvDone] = useState(false);
    const [isLogCsvDone, setIsLogCsvDone] = useState(false);
    const [showEditAlertPatientId, setShowEditAlert] = useState(location.state && location.state.patientId);
    const [showReminderAlertSatisfactory, setShowReminderAlertSatisfactory] = useState([false, '']);
    const [showReminderAlertNotSatisfactory, setShowReminderAlertNotSatisfactory] = useState([false, '']);
    const [showDefaultReminderPatient, setShowDefaultReminderPatient] = useState(null);
    const [showErrorReminder, setShowErrorReminder] = useState(null);
    const [showCustomReminderPatient, setShowCustomReminderPatient] = useState(null);
    const [showDownloadCSVModal, setShowDownloadCSVModal] = useState(null);
    const [hasPendingSymptoms, setHasPendingSymptoms] = useState(false);
    const [hasPendingIpos, setHasPendingIpos] = useState(false);

    const [missedIpos, setMissedIpos] = useState('');
    const [missedSymptoms, setMissedSymptoms] = useState('');
    const [buttonsColoring, setButtonsColoring] = useState(null);

    const { SearchBar } = Search;
    const { trackPageView } = useMatomo()

    useEffect(() => {
        trackPageView()
    }, [])
    const timer = (time = 5000) => {
        setTimeout(() => {
            handleCloseEditAlert();
        }, time);
    }

    const handleCloseEditAlert = () => {
        setShowEditAlert(null);
        location.state.patientId = null;
    }
    const handleOptionEdit = patientId => {
        setPatientIdToEdit(patientId);
    }

    const handleShowDefaultReminder = patient => {
        setShowDefaultReminderPatient(patient);
    }

    const handleShowErrorReminder = patient => {
        setShowErrorReminder(patient);
    }

    const handleShowCustomReminder = async patient => {
        try {
            // ! Patched because when closing the modal this function is called again with patient as null
            if (!patient) {
                setShowCustomReminderPatient(null);
                return
            }

            const resp = await NotificationsService.getLogsByTypeAndPatient('notification', patient.id);
            const response = getResponseData(resp)
            const result = response?.values ? Object.keys(response.values).map((key) => response.values[key]) : []
            setLogsByPatient(result);
        } catch (err) {
            console.error(err)
        }
        setShowCustomReminderPatient(patient);
    }

    const handleReminderSendingPerformed = (satisfactory, message) => {
        satisfactory ? setShowReminderAlertSatisfactory([true, message]) : setShowReminderAlertNotSatisfactory([true, message])
        setTimeout(function () {
            setShowReminderAlertSatisfactory([false, ''])
        }.bind(this), 2000)
        setShowDefaultReminderPatient(null);
        setShowCustomReminderPatient(null);
    }

    const handleShowDownload = (mustShow) => {
        setShowDownloadCSVModal(mustShow);
    }

    const rowClasses = row => showEditAlertPatientId && row.key === showEditAlertPatientId ? "table-success" : "align-middle";

    const defaultSorted = [{
        dataField: 'id',
        order: 'asc'
    }];

    const createLogCsvData = async () => {
        const logCsvDataHeaders = [["ID", "Created At", "User", "Patient ID", "Type", "Log"]]

        setIsLogCsvDone(true);

        try {
            const notificationsData = await NotificationsService.getAllLogsByType('notification', myStorageManager.getClinicId());
            const notificationResponse = getResponseData(notificationsData)

            const remindersData = await NotificationsService.getAllLogsByType('reminder', myStorageManager.getClinicId());
            const reminderResponse = getResponseData(remindersData)

            const mergeNotificationsAndReminders = { values: { ...notificationResponse?.values, ...reminderResponse?.values } }

            const results = Object.keys(mergeNotificationsAndReminders.values).map((key) => mergeNotificationsAndReminders.values[key]);

            const logCsvDataValues = results.map(result => {
                return ([
                    result.id,
                    result.created_at,
                    result.user,
                    result.patient_id,
                    result.type,
                    result.log,
                ])
            })

            setLogsCsvData([...logCsvDataHeaders, ...logCsvDataValues]);

        } catch (err) {
            console.error(err)
        }
    }

    const createCsvData = () => {
        const csvDataHeaders = [["Patient ID", "Form 1", "Form 2", "Form 3", "Form 4", "Age", "Gender", "Living Alone", "Marital Status", "Ethnicity", "Education", "Carer", "Caring for Other", "Residence", "Main Diagnosis", "Other Diagnosis", "Caregiver Age", "Caregiver Gender", "Caregiver Marital Status", "Caregiver Ethnicity", "Caregiver Education", "Relationship",]]

        const csvDataValues = Object.values(patientsData).map(patient => [
            patient.id,
            patient.iposStatusReports.length !== 0 ? patient.iposStatusReports[0].date : '-',
            patient.iposStatusReports.length !== 0 ? patient.iposStatusReports[1].date : '-',
            patient.iposStatusReports.length !== 0 ? patient.iposStatusReports[2].date : '-',
            patient.iposStatusReports.length !== 0 ? patient.iposStatusReports[3].date : '-',
            patient.age,
            patient.gender,
            patient.live,
            patient.marital,
            patient.ethnicity === "Other, please specify" ? patient.ethnicityText : patient.ethnicity,
            patient.education === "Other, please specify" ? patient.educationText : patient.education,
            patient.familyCare,
            patient.careFamily,
            patient.placeResidence === "Other, please specify" ? patient.placeResidenceText : patient.placeResidence,
            patient.patientDiagnosis,
            patient.otherDiagnosis,
            patient.careAge,
            patient.careGender,
            patient.careMarital,
            patient.careEthnicity === "Other, please specify" ? patient.careEthnicityText : patient.careEthnicity,
            patient.careEducation === "Other, please specify" ? patient.careEducationText : patient.careEducation,
            (patient.careRelationship === "Other, please specify" || patient.careRelationship === "Relative, please specify") ? patient.careRelationshipText : patient.careRelationship,
        ])

        setCsvData([...csvDataHeaders, ...csvDataValues]);
        setIsCsvDone(true);
    }

    useEffect(() => {
        if (location.state && location.state.patientId)
            timer();
    }, [location.state])

    useEffect(() => {
        const getPatientsData = async () => {
            try {
                const response = await PatientsService.getPatients(myStorageManager.getClinicId());
                setPatientsData(getResponseData(response) || {});
            } catch (err) {
                logout();
            }
        };
        getPatientsData();
    }, []);

    useEffect(() => {
        const getReportsSummaryData = async () => {
            try {
                const response = await ReportsService.getSummary(myStorageManager.getClinicId());
                const data = getResponseData(response);
                const summary = data.summary;
                const coloring = data.coloring;
                setMissedIpos(String(summary["birmingham"]));
                /* 
                const response = await ReportsService.getSummary(myStorageManager.getClinicId());
                const response2 = await ReportsService.getIpos(myStorageManager.getClinicId());
                let array = response2.data.data == null ? '0' : Object.keys(response2.data.data).length
                const data = getResponseData(response);
                const summary = data.summary;
                const coloring = data.coloring;
                setMissedIpos(array); //TODO This has to be changed with the correct API call */
                setMissedSymptoms(String(summary["birmingham"]));
                setButtonsColoring(coloring || {})
            } catch (err) {
                console.error(err)
                setMissedIpos('?');
                setMissedSymptoms('?');
            }
        };
        getReportsSummaryData();
    }, []);

    const getCoMorbidities = (patient) => {
        if (patient) {

            let rowList = [];
            for (let i = 0; i < patient.length; i++) {
                const text = patient[i];
                if (text) {
                    rowList.push(
                        <Row key={i}>
                            <Col>
                                {text}
                            </Col>
                        </Row>
                    )
                }
            }
            return rowList;
        }
    }

    useEffect(() => {
        setTimeout(() => {
            createLogCsvData()
        }, 1000)
    }, [showCustomReminderPatient, showDefaultReminderPatient]);

    if (!isCsvDone && patientsData) {
        createCsvData()
    }
    if (!isLogCsvDone && patientsData) {
        createLogCsvData()
    }
    const hasPendingForms = async (patientData) => {
        try {
            const resp = await NotificationsService.getLogsByTypeAndPatient('reminder', patientData.id);
            const response = getResponseData(resp)
            const result = response?.values ? Object.keys(response?.values).map((key) => response?.values[key]) : []
            setLogsByPatient(result);
        } catch (err) {
            console.error(err)
        }

        const hasPendingReport = patientData.iposStatusReports.some((iposReport) => iposReport.state === 'pending')

        if (hasPendingReport) {
            setHasPendingIpos(true);
        }

        return handleShowDefaultReminder(patientData)
    }

    if (!isLoggedIn) {
        return <Redirect to="/login" />
    } else {
        return (
            patientIdToEdit ?
                <Redirect
                    push
                    to={{
                        pathname: `/patients/${patientIdToEdit}`,
                        state: { patientData: patientsData[patientIdToEdit] }
                    }} /> :
                <>
                    <CustomNavbar />
                    {patientsData && <h1 className="text-center mb-3">Patients table</h1>}
                    <Container fluid className="min-vw-100 px-3 mb-3">
                        <Row>
                            <Col xs={12} sm={8} md={5} lg={4} className="pt-3">
                                <MissedReportsCard formTitle="Unactioned IPOS reports" missedReports={missedIpos} />
                            </Col>

                            {/*<Col xs={12} sm={8} md={5} lg={4} className="pt-3">
                                <MissedReportsCard formTitle="Missed Symptoms reports" missedReports={missedSymptoms}/>
                            </Col>*/}
                        </Row>
                    </Container>

                    {showEditAlertPatientId && (
                        <Row className="text-center justify-content-center mx-2">
                            <Col xs={12} md={10} xl={6}>
                                <Alert
                                    className="rounded-pill"
                                    variant="success"
                                    onClose={handleCloseEditAlert}
                                    dismissible
                                >
                                    <ImUserCheck size="2rem" />
                                    {`The patient with ID ${showEditAlertPatientId} was successfully edited!`}
                                </Alert>
                            </Col>
                        </Row>
                    )}

                    {showReminderAlertSatisfactory[0] && (
                        <Row className="text-center justify-content-center mx-2">
                            <Col xs={12} md={10} xl={6}>
                                <Alert
                                    className="rounded-pill"
                                    variant="success"
                                    onClose={() => setShowReminderAlertSatisfactory([false, ''])}
                                    dismissible
                                >
                                    <BiMessageCheck size="2rem" />
                                    {showReminderAlertSatisfactory[1] || 'The reminder was successfully sent to the patient!'}
                                </Alert>
                            </Col>
                        </Row>
                    )}

                    {showReminderAlertNotSatisfactory[0] && (
                        <Row className="text-center justify-content-center mx-2">
                            <Col xs={12} md={10} xl={6}>
                                <Alert
                                    className="rounded-pill"
                                    variant="danger"
                                    onClose={() => setShowReminderAlertNotSatisfactory([false, ''])}
                                    dismissible
                                >
                                    <BiMessageAltX size="2rem" />
                                    {showReminderAlertNotSatisfactory[1] || 'The reminder could not be sent to the patient. Please, try again later.'}
                                </Alert>
                            </Col>
                        </Row>
                    )}

                    {patientsData && buttonsColoring ?
                        <Container fluid>
                            <Button className='mr-2' onClick={() => {
                                handleShowDownload(true)
                            }}>Export as CSV</Button>
                            <CSVLink data={logsCsvData} filename='logs_table.csv'><Button>Export Logs</Button></CSVLink>

                            <ToolkitProvider
                                keyField="key"
                                bootstrap4
                                search
                                data={Object.values(patientsData).map(patient => {
                                    //var symptomsCount = patient.covidStatusReports.length;
                                    var iposCount = patient.iposStatusReports.length;
                                    var symptomsDoneCount = 0;
                                    var iposDoneCount = 0;
                                    for (let i = 0; i < patient.iposStatusReports.length; i++) {
                                        if (patient.iposStatusReports[i].state === 'answered') {
                                            for (let j = 0; j < patient.iposStatusReports[i].reports.length; j++) {
                                                iposDoneCount = iposDoneCount + 1;
                                            }
                                        }
                                        if (patient.iposStatusReports[i].state === 'closed') {
                                            iposCount = iposCount - 1;
                                        }
                                    }
                                    return {
                                        key: patient.id,
                                        progress: {
                                            patientData: patient,
                                            id: patient.id,
                                            ipos: patient.iposStatusReports,
                                            patientId: patient.id,
                                            iposCount: iposCount,
                                            iposDoneCount: iposDoneCount,
                                            symptomsDoneCount: symptomsDoneCount
                                        },
                                        age: patient.age,
                                        form1: patient.iposStatusReports.length !== 0 ? patient.iposStatusReports[0].date.split("-").reverse().join("-") : '',
                                        form2: patient.iposStatusReports.length !== 0 ? patient.iposStatusReports[1].date.split("-").reverse().join("-") : '',
                                        form3: patient.iposStatusReports.length !== 0 ? patient.iposStatusReports[2].date.split("-").reverse().join("-") : '',
                                        form4: patient.iposStatusReports.length !== 0 ? patient.iposStatusReports[3].date.split("-").reverse().join("-") : '',
                                        gender: patient.gender,
                                        live: patient.live,
                                        marital: patient.marital,
                                        ethnicity: patient.ethnicity === "Other, please specify" ? patient.ethnicityText : patient.ethnicity,
                                        education: patient.education === "Other, please specify" ? patient.educationText : patient.education,
                                        familyCare: patient.familyCare,
                                        careFamily: patient.careFamily,
                                        placeResidence: patient.placeResidence === "Other, please specify" ? patient.placeResidenceText : patient.placeResidence,
                                        mainDiagnosis: patient.patientDiagnosis,
                                        otherDiagnosis: patient.otherDiagnosis,
                                        careAge: patient.careAge,
                                        careGender: patient.careGender,
                                        careMarital: patient.careMarital,
                                        careEthnicity: patient.careEthnicity === "Other, please specify" ? patient.careEthnicityText : patient.careEthnicity,
                                        careEducation: patient.careEducation === "Other, please specify" ? patient.careEducationText : patient.careEducation,
                                        careRelationship: (patient.careRelationship === "Other, please specify" || patient.careRelationship === "Relative, please specify") ? patient.careRelationshipText : patient.careRelationship,

                                        actions:
                                            <Dropdown>
                                                <Dropdown.Toggle variant="primary">
                                                    <BsThreeDotsVertical size="1rem" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu align="left">
                                                    <Dropdown.Item onClick={() => handleOptionEdit(patient.id)}>
                                                        <BsPencil className="mr-2" />
                                                        Edit
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={
                                                            () => hasPendingForms(patient)
                                                        }>
                                                        <BsAlarm className="mr-2" />
                                                        Reminder
                                                    </Dropdown.Item>

                                                    <Dropdown.Item
                                                        onClick={() => handleShowCustomReminder(patient)}>
                                                        <BsReply className="mr-2" />
                                                        Message
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                    }
                                })}
                                columns={[
                                    getColumnObjHidden('key', " Patien222 ID", cell => cell || '-', true, true),
                                    getColumnObj(
                                        'progress',
                                        'Patient ID',
                                        cell => {
                                            const hasPendingReport = cell.patientData.iposStatusReports.some((iposReport) => iposReport.state === 'pending')

                                            if (hasPendingReport) {
                                                setHasPendingIpos(true);
                                            }

                                            return (
                                                <Col style={{ minWidth: '15em' }}>
                                                    <div>
                                                        {cell.id || '-'}
                                                    </div>

                                                    {cell.id && (
                                                        <Row>
                                                            <Col>
                                                                Forms
                                                            </Col>

                                                        </Row>
                                                    )}
                                                    <div className='separator' />
                                                    <Row>
                                                        <Col>
                                                            <Link to={`/ipos/${cell.id}`}
                                                                style={getStyleByColorName('white', '#000000')}
                                                                className="my-1 btn btn-outline-dark">IPOS</Link>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            )
                                        },
                                        (a, b, order, dateField, rowA, rowB) => {
                                            if (order === 'asc') {
                                                return rowA.key.localeCompare(rowB.key);
                                            }
                                            return rowB.key.localeCompare(rowA.key);
                                        },
                                        true
                                    ),
                                    getColumnObj('actions', 'Options', cell => cell || '-'),
                                    getColumnObj('form1', 'Form 1', cell => cell || '-'),
                                    getColumnObj('form2', 'Form 2', cell => cell || '-'),
                                    getColumnObj('form3', 'Form 3', cell => cell || '-'),
                                    getColumnObj('form4', 'Form 4', cell => cell || '-'),
                                    getColumnObj('age', 'Age', cell => cell || '-'),
                                    getColumnObj('gender', 'Gender', cell => cell || '-'),
                                    getColumnObj('live', 'Living Alone', cell => cell || '-'),
                                    getColumnObj('marital', 'Marital Status', cell => cell || '-'),
                                    getColumnObj('ethnicity', 'Ethnicity', cell => cell || '-'),
                                    getColumnObj('education', 'Education', cell => cell || '-'),
                                    getColumnObj('familyCare', 'Carer', cell => cell || '-'),
                                    getColumnObj('careFamily', 'Caring for Other', cell => cell || '-'),
                                    getColumnObj('placeResidence', 'Residence', cell => cell || '-'),
                                    getColumnObj('mainDiagnosis', 'Main Diagnosis', cell => cell || '-'),
                                    getColumnObj('otherDiagnosis', 'Other Diagnosis', cell => cell || '-'),
                                    getColumnObj('careAge', 'Caregiver Age', cell => cell || '-'),
                                    getColumnObj('careGender', 'Caregiver Gender', cell => cell || '-'),
                                    getColumnObj('careMarital', 'Caregiver Marital Status', cell => cell || '-'),
                                    getColumnObj('careEthnicity', 'Caregiver Ethnicity', cell => cell || '-'),
                                    getColumnObj('careEducation', 'Caregiver Education', cell => cell || '-'),
                                    getColumnObj('careRelationship', 'Relationship', cell => cell || '-'),
                                ]}
                            >
                                {props =>
                                    <div>
                                        <div className="my-3" />

                                        <SearchBar className="my-3"
                                            size="lg"
                                            placeholder="Search by Patient ID..." {...props.searchProps}
                                        />

                                        <PaginationProvider pagination={paginationFactory({
                                            custom: true,
                                            totalSize: Object.keys(patientsData).length
                                        })}>
                                            {
                                                ({
                                                    paginationProps,
                                                    paginationTableProps
                                                }) => (
                                                    <div>
                                                        <PaginationListStandalone
                                                            {...paginationProps}
                                                        />

                                                        <BootstrapTable
                                                            defaultSorted={defaultSorted}
                                                            striped
                                                            hover
                                                            bordered
                                                            wrapperClasses="table-responsive my-3"
                                                            rowClasses={rowClasses}
                                                            {...paginationTableProps}
                                                            {...props.baseProps}
                                                        />
                                                        <PaginationListStandalone
                                                            {...paginationProps}
                                                        />
                                                    </div>
                                                )
                                            }
                                        </PaginationProvider>

                                    </div>
                                }
                            </ToolkitProvider>

                        </Container> :
                        <div className="text-center">
                            <BasicSpinner />
                        </div>
                    }

                    {
                        showErrorReminder &&
                        <ReminderErrorModal
                            title='Error'
                            description='This patient has no pending forms'
                            handleShow={handleShowErrorReminder}
                            showModal={true}
                        />
                    }

                    {
                        showDefaultReminderPatient &&
                        <SendReminderModal
                            hasPendingSymptoms={hasPendingSymptoms}
                            hasPendingIpos={hasPendingIpos}
                            patient={showDefaultReminderPatient}
                            handleShow={handleShowDefaultReminder}
                            handleSendingPerformed={handleReminderSendingPerformed}
                            showModal={true}
                            custom={false}
                            logs={logsByPatient}
                        />
                    }

                    {
                        showCustomReminderPatient &&
                        <SendReminderModal
                            patient={showCustomReminderPatient}
                            handleShow={handleShowCustomReminder}
                            handleSendingPerformed={handleReminderSendingPerformed}
                            showModal={true}
                            custom={true}
                            logs={logsByPatient}
                        />
                    }
                    {
                        showDownloadCSVModal &&
                        <DownloadPatientsCSVModal
                            title='Download the content as CSV'
                            description='Do you want to download the content as CSV?'
                            csvData={csvData}
                            handleDownloadValue={() => {
                                console.log('sending value')
                            }}
                            handleShow={handleShowDownload}
                            showModal={showDownloadCSVModal}
                        />
                    }
                </>
        );
    }
}

export default PatientsTable