import { MyAxiosInstance } from "../../utils";
import {
    REPORT_KEY_URL,
    REPORTS_SUMMARY,
    REPORT_UPDATE,
    REPORT_DELETE,
    PATIENT_REPORTS_URL,
    ALL_ACTION_LOGS,
    ALL_ACTION_LOGS_OF_REPORT,
    EXPORT_PDF
} from "../constants/myApiUrls";


// * Perform a GET request to the reports URL to retrieve the IPOS data.
const getIpos = (clinic_id) => MyAxiosInstance.get(REPORT_KEY_URL('birmingham', clinic_id));

// * Perform a GET request to the reports URL to retrieve the IPOS data for a specific patient.
const getPatientIpos = id => MyAxiosInstance.get(PATIENT_REPORTS_URL(id, 'birmingham'));

// * Perform a GET request to the reports URL to retrieve the COVID data.
const getSymptoms = () => MyAxiosInstance.get(REPORT_KEY_URL('symptom-questionnaire'));

// * Perform a GET request to the reports URL to retrieve the COVID data for a specific patient.
const getPatientSymptoms = id => MyAxiosInstance.get(PATIENT_REPORTS_URL(id, 'symptom-questionnaire'));

// * Perform a PUT request to the IPOS URL to update the IPOS data
const updateIpos = (updatedPatientObject) => MyAxiosInstance.put(REPORT_UPDATE(updatedPatientObject.id), updatedPatientObject);

// * Perform a PUT request to the Covid URL to update the Covid data
const updateSymptoms = (params, id) => MyAxiosInstance.put(REPORT_UPDATE(id), params);

// * Perform a PUT request to the Covid URL to update the Covid data
const deleteReport = (id) => MyAxiosInstance.delete(REPORT_DELETE(id));

// * Perform a GET request to retrieve a summary of the unactioned reports
const getSummary = (clinic_id) => MyAxiosInstance.get(REPORTS_SUMMARY(clinic_id));

// * Perform a GET request to retrieve a summary of the all actions logs data
const getAllActionLogs = (type, clinic_id) => MyAxiosInstance.get(ALL_ACTION_LOGS(type, clinic_id))

// * Perform a GET request to retrieve a summary of the actions logs data for the selected row
const getActionLogsOfReport = (type, reportId) => MyAxiosInstance.get(ALL_ACTION_LOGS_OF_REPORT(type, reportId))

const getReportPDF = (id) => MyAxiosInstance.get(EXPORT_PDF(id))

export default {
    getIpos,
    getPatientIpos,
    getSymptoms,
    getPatientSymptoms,
    updateIpos,
    updateSymptoms,
    getSummary,
    deleteReport,
    getAllActionLogs,
    getActionLogsOfReport
};