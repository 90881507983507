import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import Notifications from "./Notifications";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../context/auth";
import Logo from "../../reusable/Logo/Logo";
import NotificationsService from "../../../utils/services/NotificationsService";
import { getResponseData } from "../../../utils/helperFunctions";
import { MyStorageManager } from "../../../utils";

const CustomNavbar = () => {
    const page = window.location.href.split("/")[3]
    const myStorageManager = new MyStorageManager();
    const { logout } = useAuth();
    const [notifications, setNotifications] = useState({});

    useEffect(() => {
        const getNotificationsData = async () => {
            try {
                const response = await NotificationsService.getNotifications();
                setNotifications(getResponseData(response) || {});
            } catch (err) {
            }
        };
        getNotificationsData();
        /*setNotifications(mockNotifications)*/
    }, []);

    const handleSelect = selectedKey => {
        if (selectedKey === "logout") {
            logout();
        }
    }

    const handleMarkNotificationsAsSeen = e => {
        e.preventDefault();
        setNotifications({});
        myStorageManager.clearNotifications();
        const data = {
            ids: Object.keys(notifications)
        };

        // Using the notifications service
        NotificationsService.markNotificationsAsSeen(data)
            .then(_ => {
                setNotifications({});
                myStorageManager.clearNotifications();
            }).catch(_ => {
            });
    }

    return (
        <Navbar bg="dark" variant="dark" expand="lg" sticky='top' className="mb-5">
            <Navbar.Brand as={Link} to="/">
                <Logo />
                IPOS Dashboard
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                <Nav className="mr-auto">
                    <Nav.Link style={{ color: "white" }} as={Link} to="/patients">Patients table</Nav.Link>
                    {/* // * The below link replaced the conditional rendering commented out after it which is buggy (when navigating from /ipos/patientId to /ipos) */}
                    <Nav.Link style={{ color: "white" }} as={Link} to="/ipos">IPOS table</Nav.Link>
                    {/* {page != "ipos" ? <Nav.Link style={{ color: "white" }} as={Link} to="/ipos">IPOS table</Nav.Link> :
                        <Nav.Link style={{ color: "white" }} as={NavLink} to="/ipos" >IPOS table else</Nav.Link>
                    } */}

                    {/* <NavDropdown title="Reports" id="reports-dropdown">
                        <NavDropdown.Item as={Link} to="/ipos">IPOS</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/symptoms">Symptoms</NavDropdown.Item>
                    </NavDropdown>*/}
                    {/*<Nav.Link as={Link} to="/user-management">User management</Nav.Link>*/}
                </Nav>

                {/* <Notifications notifications={Object.values(notifications)} onMarkAsSeen={handleMarkNotificationsAsSeen} /> */}

                <Nav onSelect={selectedKey => handleSelect(selectedKey)}>
                    <Nav.Link eventKey="logout">Log out</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar >
    );
}

export default CustomNavbar;