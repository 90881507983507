import { createSlice } from '@reduxjs/toolkit'
import ReportsService from 'utils/services/ReportsService'
import { MyStorageManager } from 'utils'

const iposDataSlice = createSlice({
  name: 'iposData',
  initialState: null,
  reducers: {
    setIposData(state, action) {
      return action.payload
    },
    updateIposData(state, action) {
      let updatedIposData = {}
      for (const [key, value] of Object.entries(state.data)) {
        if (key !== action.payload.id) updatedIposData[key] = value
        else updatedIposData[key] = action.payload
      }
      return { ...state, data: updatedIposData }
    },
    removeIposData(state, action) {
      // const  Object.values(state.data).filter((iposData) => iposData.iposData_id !== action.payload)
      let newIposData = {}
      for (const [key, value] of Object.entries(state.data)) {
        if (key !== action.payload) newIposData[key] = value
      }
      return { ...state, data: newIposData }
    },
  },
})

export const {
  setIposData,
  updateIposData,
  removeIposData,
} = iposDataSlice.actions

const myStorageManager = new MyStorageManager();

export const initializeIposData = () => {
  return async (dispatch) => {
    const iposData = await ReportsService.getIpos(myStorageManager.getClinicId())
    dispatch(setIposData(iposData.data))
  }
}

export const clearState = () => {
  return async (dispatch) => {
    dispatch(setIposData({}))
  }
}

export const updateIposDataAction = (newIposDataObject, notification, updateLogsExports) => {
  return async (dispatch) => {
    try {
      await ReportsService.updateIpos(newIposDataObject)
      dispatch(updateIposData(newIposDataObject))
      updateLogsExports()
      notification(true, true)
    } catch (exception) {
      console.error(exception)
    }
  }
}

export const deleteIposDataAction = (id) => {
  return async (dispatch) => {
    try {
      await ReportsService.deleteReport(id);
      dispatch(removeIposData(id))
    } catch (exception) {
      console.error(exception)
      // TODO: implement notifications reducer
      // dispatch(
      //   setNotification(
      //     'There has been some error trying to delete this iposData',
      //     'alert',
      //     5
      //   )
      // )
    }
  }
}

export default iposDataSlice.reducer