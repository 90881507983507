import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import App from "./components/App/App";
import { FirebaseAppProvider } from 'reactfire'
import firebaseConfig from './firebase-config';
import { MatomoProvider, createInstance } from '@jonkoops/matomo-tracker-react'
import { Provider } from 'react-redux'
import {store, persister} from 'store';
import { PersistGate } from "redux-persist/integration/react";

const instance = createInstance({
    urlBase: "https://matomo.uhda.health/",
    siteId: 4,
})

ReactDOM.render(
    <MatomoProvider value={instance}>
        <Provider store={store} >
            <PersistGate loading={null} persistor={persister}>
               <App />
            </PersistGate>
        </Provider>
    </MatomoProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
