import React, {useState} from 'react';
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {Link, Redirect} from "react-router-dom";
import {mockNotifications} from "../../mocks/Navbar/mockNotifications";
import Notifications from "./Navbar/Notifications";
import {useAuth} from "../../context/auth";
import CustomNavbar from "./Navbar/CustomNavbar";

/**
 * Component to show the dashboard
 *
 * @component
 *
 * @example
 * <Dashboard/>
 */

export default function Dashboard() {
    const {isLoggedIn} = useAuth();

    return (
        !isLoggedIn ?
            <Redirect to="/login"/> :
            <>
                <CustomNavbar/>
                <Container className="d-flex align-items-center justify-content-center min-vh-100">
                    <h1>Dummy Dashboard</h1>
                </Container>
            </>
    )
}