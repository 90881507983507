const reportsConstants = {
    POS_Pain: 'Pain',
    POS_BreathShortness: 'Shortness of breath',
    POS_WeaknessLackEnergy : 'Weakness or lack of energy',
    POS_Nausea : 'Nausea',
    POS_Vomiting : 'Vomiting',
    POS_PoorAppetite : 'Poor appetite',
    POS_Constipation : 'Constipation',
    POS_Sore : 'Sore or dry mouth',
    POS_Drowsiness : 'Drowsiness',
    POS_PoorMobility : 'Poor mobility',
}

export default reportsConstants