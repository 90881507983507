import axios from 'axios';
import {BASE_PATH} from "../../constants/myApiUrls";
import {MyStorageManager} from "../../index";

/**
 * @return {object} Axios instance with the Base Url and the token (if there is a token in the local storage)
 */
const fetchClient = () => {
    const myStorageManager = new MyStorageManager();
    const defaultOptions = {
        baseURL: BASE_PATH,
        headers: {
            'Content-Type': 'application/json',
        }
    };

    // Create instance
    let instance = axios.create(defaultOptions);

    // Set the auth token for any request if the token exists in the local storage
    instance.interceptors.request.use(config => {
        const token = myStorageManager.getToken();
        if (token) config.headers.Authorization = `Bearer ${token}`;
        return config;
    });

    return instance;
};

export default fetchClient();

