export const WHITE ='#FFFFFF';
export const GREEN = '#60c960';
export const ORANGE = '#FFA500';
export const YELLOW = '#FFFF7D';
export const RED = '#e63131';
export const BLUE = '#448AFF';
export const GREY = '#9E9E9E';

const colors = {
    WHITE,
    GREY,
    BLUE,
    GREEN,
    ORANGE,
    RED,
    YELLOW
};

export const getStyleByColorName = (colorName, colorText) => (
    {
        backgroundColor: colors[colorName.toUpperCase()],
        color: colorText ? colorText : "#FFFFFF",
        fontSize: 14,
        width:'110px'
    }
);

export const getStyleByColorProgress = colorName => (
    {
        backgroundColor: colors[colorName.toUpperCase()],
        color: "#000000",
        fontSize: 14,
        width:'70px'
    }
);