const userRoles = {
    /*SUPER_ADMIN: {
        key: 'SUPER_ADMIN',
        name: 'Super admin',
        users: ['Leeds', 'APCA', 'UD'],
        description: 'Access all areas of the dashboard',
    },
    CLINICIAN: {
        key: 'CLINICIAN',
        name: 'Clinician',
        users: ['HCW'],
        description: 'Access all aspects of the dashboard, except for User management panel',
    },
    APP_USER: {
        key: 'APP_USER',
        name: 'App user',
        users: ['Patients', 'Caregiver'],
        description: 'Submit form data via app',
    }*/

    SUPER_ADMIN: {
        key: 'SUPER_ADMIN',
        name: 'Super admin',
        id: 1
    },
    ADMIN: {
        key: 'ADMIN',
        name: 'Admin',
        id: 2
    },
    PATIENT: {
        key: 'PATIENT',
        name: 'Patient',
        id: 3
    },
    VISITOR: {
        key: 'VISITOR',
        name: 'Visitor',
        id: 4
    },
}

export default userRoles;