import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import Login from "../Login/Login";
import Dashboard from "../Dashboard/Dashboard";
import { AuthContext, useAuth } from "../../context/auth";
import { MyStorageManager } from "../../utils";
import NotFound from "../NotFound";
import { isValidToken } from "../../utils/helperFunctions";
import PatientsTable from "../PatientsTable";
import IposTable from "../IposTable";
import EditPatientDemographics from "../EditPatientDemographics/EditPatientDemographics";
import SymptomsTable from '../SymptomsTable/SymptomsTable';
import SubmissionCalendarTable from '../SubmissionCalendar';
import { useDispatch } from 'react-redux';
import { initializeIposData } from 'reducers/iposDataReducer';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isLoggedIn } = useAuth();
  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

function App() {
  const myStorageManager = new MyStorageManager();

  const [token, setToken] = useState(myStorageManager.getToken());
  const [roles, setRoles] = useState(null);
  //const [expirationDate, setExpirationDate] = useState(myStorageManager.getExpirationDate());

  const login = (userToken, userRoles) => {
    setToken(userToken);
    setRoles(userRoles);
    //setExpirationDate(userTokenExpirationDate);
  }
  const logout = () => {
    setToken(null);
    setRoles(null);
    //setExpirationDate(null);

    myStorageManager.clearExceptCredentials();
  }

  const dispatch = useDispatch()

  useEffect(() => {
    // ! Redux persist implemented but trumped by this method
    if (token) dispatch(initializeIposData())
  }, [token, dispatch])

  return (
    <AuthContext.Provider value={
      { isLoggedIn: isValidToken(token), token, roles, login, logout }
    }>
      <Router>
        <Switch>
          <Route path="/login" component={Login} />
          <Route exact path="/" component={() => <Redirect to="/login" />} />
          {/* <PrivateRoute path="/test" component={IposTable} /> */}
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/patients/:patientId" component={EditPatientDemographics} />
          <PrivateRoute path="/patients" component={PatientsTable} />
          <PrivateRoute path="/ipos/:patientId" component={IposTable} />
          <PrivateRoute path="/ipos/" component={IposTable} />
          <PrivateRoute path="/symptoms/:patientId" component={SymptomsTable} />
          <PrivateRoute path="/symptoms" component={SymptomsTable} />
          <PrivateRoute path="/calendar/:type" component={SubmissionCalendarTable} />
          {/*<PrivateRoute path="/user-management" component={UserManagementPage}/>*/}
          <Route component={NotFound} />
        </Switch>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
