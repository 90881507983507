import React from "react";

const BadgeColor = ({data}) => {
    return (
        /*<div className={`badge custom-badge badge-primary custom-badge-primary ${data === "Yes" ?
            "badge-outlined custom-badge-outlined" : ""}`}>
            {data}
        </div>*/
        <div className={`badge custom-badge ${data === "Yes" ?
            "custom-badge-yes" : "custom-badge-no"}`}>
            {data}
        </div>
    )
}

export default BadgeColor;